<template>
    <div v-if="!!record.id">
        <div class="columns">
            <div class="column col-6 col-sm-12">
                <user-management
                    :record="record"
                    class="mb-8"
                />

                <lockdowns
                    class="mb-8"
                />
            </div>
            <div class="column col-6 col-sm-12">
                <student-management
                    :record="record"
                    class="mb-8"
                />
            </div>
        </div>
    </div>
</template>

<script>
import is_settings_panel from "./IsSettingsPanel"

import UserManagement from "./UserManagement"
import Lockdowns from "./Lockdowns"
import StudentManagement from "./StudentManagement"

export default {
    name: "UsersPanel",
    mixins: [is_settings_panel],
    components: {
        UserManagement,
        Lockdowns,
        StudentManagement
    }
}
</script>
