<template>
    <div >
        <h6>{{ $root.translate("Visual Password Images") }}</h6>

        <div class="columns uploaders">
            <div
                class="column col-2 col-lg-3 col-md-4 col-sm-6 p-3"
            >
                <image-upload-input
                    id="password-token-empty"
                    name="url"
                    url=""
                    :alt="$root.translate('New image')"
                    :accepts="upload_accepts('url')"
                    :uploading="!!uploading['url']"
                    :uploaded="uploaded['url'] || 0"
                    :error="has_error('url')"
                    @upload="create_token"
                >
                    <template v-slot:dragging-title><span></span></template>
                    <template v-slot:empty-title><span></span></template>
                    <template v-slot:empty-button-title>
                        {{ $root.translate("Upload") }}
                    </template>
                </image-upload-input>
            </div>
            <div
                v-for="attachment in filtered_rows"
                :key="attachment.id"
                class="column col-2 col-lg-3 col-md-4 col-sm-6 p-3"
            >
                <uploader
                    :attachment="attachment"
                />
            </div>
        </div>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import is_image_library from "./IsImageLibrary"

import Uploader from "./Uploader"

export default {
    name: "PasswordTokens",
    mixins: [is_image_library],
    components: {
        Uploader
    },
    methods: {
        create_token (files) {
            this.upload_file_list("url", files)
            this.record_new().then(() => {
                if(this.edited_record) {
                    this.edited_record.name = this.held.url.name
                    this.record_create()
                }
            })
        }
    },
    computed: {
        state_identifier () {
            return "password-tokens"
        }
    },
    data () {
        return {
            entityName: "Image:::Images",

            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: "type",
                filter: "password",
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>

<style lang="scss">
.uploaders {
    .nibnut-document-uploader > .empty {
        padding: 0;
    }
}
</style>
