<template>
    <div v-if="!!record.id">
        <div class="columns">
            <div class="column col-6 col-sm-12">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="card-title h5">{{ $root.translate("Site URLs") }}</div>
                        <div class="card-subtitle text-gray">{{ $root.translate("Leave any value empty to hide the link on the application") }}</div>
                    </div>
                    <div class="card-body">
                        <div class="columns">
                            <div
                                v-for="site_url in site_urls"
                                :key="site_url.id"
                                class="column col-6 col-md-12"
                            >
                                <form-input
                                    :id="site_url.id"
                                    :name="site_url.id"
                                    v-model="record[site_url.id]"
                                    :required="false"
                                    :saving="saving(site_url.id)"
                                    :error="has_error(site_url.id)"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ site_url.label }}</template>
                                </form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mb-8">
                    <div class="card-header">
                        <div class="card-title h5">{{ $root.translate("External Services") }}</div>
                    </div>
                    <div class="card-body">
                        <div class="columns">
                            <div class="column col-3 col-md-4 col-sm-12">
                                <bitbucket-connector />
                            </div>
                            <div class="column col-3 col-md-4 col-sm-12">
                                <form-dropdown
                                    id="mailchimp_audience_id"
                                    name="mailchimp_audience_id"
                                    :value="record.mailchimp_audience_id"
                                    :options="record.mailchimp_audiences"
                                    :required="false"
                                    :error="has_error('mailchimp_audience_id')"
                                    @input="save"
                                >
                                    <template v-slot:label>
                                        <strong>{{ $root.translate("Mailchimp Newsletter Audience") }}</strong>
                                    </template>
                                </form-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-6 col-sm-12">
                <tags class="mb-4" />

                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ $root.translate("Troubleshooting") }}</div>
                    </div>
                    <div class="card-body">
                        <div class="columns mb-8">
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="troubleshoot_fail_rate"
                                    name="troubleshoot_fail_rate"
                                    v-model="record.troubleshoot_fail_rate"
                                    type="number"
                                    :min="1"
                                    :max="100"
                                    :required="false"
                                    :saving="saving('troubleshoot_fail_rate')"
                                    :error="has_error('troubleshoot_fail_rate')"
                                    @input="save"
                                >
                                    <template v-slot:label>
                                        {{ $root.translate("Fail Rate") }}
                                    </template>
                                    <template v-slot:right_addon><span class="input-group-addon">%</span></template>
                                </form-input>
                            </div>
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="troubleshoot_student_threshold"
                                    name="troubleshoot_student_threshold"
                                    v-model="record.troubleshoot_student_threshold"
                                    type="number"
                                    :min="1"
                                    :required="false"
                                    :saving="saving('troubleshoot_student_threshold')"
                                    :error="has_error('troubleshoot_student_threshold')"
                                    @input="save"
                                >
                                    <template v-slot:label>
                                        {{ $root.translate("Report Group Size") }}
                                    </template>
                                    <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("student(s)") }}</span></template>
                                </form-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="is_developer" class="card">
            <div class="card-header">
                <div class="card-title h5">{{ $root.translate("Developer Zone") }}</div>
            </div>
            <div class="card-body">
                <form-toggle-input
                    id="bug_reporter_active"
                    name="bug_reporter_active"
                    type="checkbox"
                    v-model="record.bug_reporter_active"
                    :true-value="1"
                    :false-value="0"
                    :required="false"
                    :saving="saving('bug_reporter_active')"
                    :error="has_error('bug_reporter_active')"
                    @input="save"
                >
                    {{ $root.translate('Bug Reporter') }}
                </form-toggle-input>
            </div>
        </div>
    </div>
</template>

<script>
import is_settings_panel from "./IsSettingsPanel"

import { FormInput, FormToggleInput, FormDropdown } from "@/nibnut/components"
import BitbucketConnector from "./BitbucketConnector"
import Tags from "./Tags"

export default {
    name: "GeneralPanel",
    mixins: [is_settings_panel],
    components: {
        FormInput,
        FormToggleInput,
        FormDropdown,
        BitbucketConnector,
        Tags
    },
    computed: {
        site_urls () {
            return [
                { id: "website_domain", label: this.$root.translate("WordPress Website Domain") },
                { id: "about_us_url", label: this.$root.translate("About Us URL") },
                { id: "blog_url", label: this.$root.translate("Blog URL") },
                { id: "subscribe_url", label: this.$root.translate("Subscribe URL") },
                { id: "faq_url", label: this.$root.translate("FAQ URL") },
                { id: "help_url", label: this.$root.translate("Help URL") },
                { id: "testimonials_url", label: this.$root.translate("Testimonials URL") },
                { id: "contact_us_url", label: this.$root.translate("Contact Us URL") },
                { id: "terms_url", label: this.$root.translate("Terms & Conditions URL") },
                { id: "privacy_url", label: this.$root.translate("Privacy Policy URL") },
                { id: "copyright_url", label: this.$root.translate("Copyright URL") },
                { id: "facebook_url", label: this.$root.translate("Facebook URL") },
                { id: "instagram_url", label: this.$root.translate("Instagram URL") },
                { id: "pinterest_url", label: this.$root.translate("Pinterest URL") },
                { id: "youtube_url", label: this.$root.translate("Youtube URL") }
            ]
        }
    }
}
</script>
