<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("User Management") }}</div>
        </div>
        <div class="card-body">
            <div class="columns">
                <div class="column col-4 col-md-12">
                    <form-input
                        id="last_login_warning_delay"
                        name="last_login_warning_delay"
                        v-model="record.last_login_warning_delay"
                        type="number"
                        :min="1"
                        :required="false"
                        :saving="saving('last_login_warning_delay')"
                        :error="has_error('last_login_warning_delay')"
                        @input="save"
                    >
                        <template v-slot:label>
                            {{ $root.translate("Last Login Warning") }}
                            <help-button
                                size="sm"
                                class="popover-right ml-2"
                            >
                                <p>{{ $root.translate("The application will send a notification to system administrator(s) when users have not logged in for this many days.") }}</p>
                            </help-button>
                        </template>
                        <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("day(s)") }}</span></template>
                    </form-input>
                </div>
                <div class="column col-8 col-md-12">
                    <invitation-expiries
                        id="default_invitation_delay"
                        name="default_invitation_delay"
                        v-model="record.default_invitation_delay"
                        :required="true"
                        :saving="saving('default_invitation_delay')"
                        :error="has_error('default_invitation_delay')"
                        @input="save"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { handles_saving } from "@/nibnut/mixins"

import { FormInput, HelpButton, InvitationExpiries } from "@/nibnut/components"

export default {
    name: "UserManagement",
    mixins: [handles_saving],
    components: {
        FormInput,
        HelpButton,
        InvitationExpiries
    },
    methods: {
        save (value, field) {
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.record.id) {
                    this.record_id = this.record.id
                    return this.save_field(this.record[field], field)
                }
            }
            return Promise.resolve()
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "setting",
            entityName: "Setting:::Settings"
        }
    }
}
</script>

<style lang="scss">
#settings {
    td {
        img {
            max-height: 1rem;
            vertical-align: middle;
        }
    }
    .image-holder {
        position: relative;

        & > div {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
