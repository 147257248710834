<template>
    <page
        id="resources-list"
        :title="translate('Resource:::Resources', {}, 2)"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <data-table
            v-if="is_at_least_administrator"
            id="resources-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="record_create('resource')"
            @click="record_edit('resource', $event.id)"
        >
            <template
                v-slot:controls
            >
                <div>
                    <form-dropdown
                        id="type"
                        name="type"
                        v-model="state.filter"
                        :options="types"
                        :required="false"
                        :empty-label="translate('All types')"
                        @input="filter('type', $event)"
                    />
                </div>
                <div>
                    <form-dropdown
                        id="label"
                        name="label"
                        v-model="state.label"
                        :options="labels"
                        :required="false"
                        :empty-label="translate('All labels')"
                        @input="filter('label', $event)"
                    />
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    <open-icon :glyph="glyph_for_row(row)" class="mr-2" />
                    {{ row.name }}
                </h6>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'name'">
                    <open-icon :glyph="glyph_for_row(row)" class="mr-2" />
                    {{ row.name }}
                </span>
                <span v-else>
                    {{ row[field] }}
                </span>
            </template>
        </data-table>
        <div
            v-else
            class="resources-picker grid-picker"
        >
            <div class="controls">
                <div>
                    <form-dropdown
                        id="type"
                        name="type"
                        v-model="state.filter"
                        :options="types"
                        :required="false"
                        empty-value=""
                        :empty-label="translate('All types')"
                        @input="filter_by('type', $event)"
                    />
                </div>
                <div>
                    <form-dropdown
                        id="label"
                        name="label"
                        v-model="state.label"
                        :options="labels"
                        :required="false"
                        :empty-label="translate('All labels')"
                        @input="filter('label', $event)"
                    />
                </div>
                <div>
                    <form-input
                        id="search"
                        name="search"
                        v-model="state.search"
                        glyph="search"
                        glyph-position="right"
                        :required="false"
                        :live="true"
                        @input="search"
                    />
                </div>
            </div>

            <div
                v-if="state.found === 0"
                class="empty"
            >
                <div class="empty-subtitle">({{ translate("No resources found...") }})</div>
            </div>
            <div
                v-else
                class="columns semi-responsive"
            >
                <div
                    v-for="row in filtered_rows"
                    :key="row.id"
                >
                    <div
                        v-if="row.type === constants('asset_types', 'TYPE_VIDEO').id"
                        class="grid-tile-container clickable"
                        @click.prevent="watch_video(row)"
                    >
                        <div
                            class="grid-tile"
                        >
                            <img
                                :src="image_url_for_resource(row)"
                                :alt="row.name"
                            />
                            <div>
                                <div class="p-6">
                                    <h6 class="text-center">{{ row.name }}</h6>
                                    <div v-html="row.description" class="text-justify"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <base-link
                        v-else-if="row.type === constants('asset_types', 'TYPE_TOOL').id"
                        :href="{ name: 'resource.tool', params: { id: row.id } }"
                        target="_blank"
                        class="column grid-tile-container"
                    >
                        <div
                            class="grid-tile"
                        >
                            <img
                                :src="image_url_for_resource(row)"
                                :alt="row.name"
                            />
                            <div>
                                <div class="p-6">
                                    <h6 class="text-center">{{ row.name }}</h6>
                                    <div v-html="row.description" class="text-justify"></div>
                                </div>
                            </div>
                        </div>
                    </base-link>
                    <base-link
                        v-else
                        :href="`/resource/${row.slug}/printable`"
                        target="_blank"
                        class="column grid-tile-container"
                        @click="track_event('Download', 'Resource', row.name)"
                    >
                        <div
                            class="grid-tile"
                        >
                            <img
                                :src="image_url_for_resource(row)"
                                :alt="row.name"
                            />
                            <div>
                                <div class="p-6">
                                    <h6 class="text-center">{{ row.name }}</h6>
                                    <div v-html="row.description" class="text-justify"></div>
                                </div>
                            </div>
                        </div>
                    </base-link>
                </div>
            </div>

            <div v-if="!loading && (nb_pages > 1)" class="my-4">
                <ul v-if="nb_pages > 1" class="pagination">
                    <li
                        :class="{ 'd-invisible': !has_prev_page }"
                        class="page-item"
                    >
                        <base-link
                            href="#"
                            @click.prevent="goto_page(1)"
                        >
                            <open-icon
                                glyph="chevron-left"
                            />
                        </base-link>
                    </li>
                    <li
                        v-for="page in pages"
                        :key="page.number"
                        :class="{ 'active': page.number === state.page }"
                        class="page-item"
                    >
                        <base-link
                            v-if="page.delta"
                            href="#"
                            @click.prevent="goto_page(state.page + page.delta)"
                        >
                            ...
                        </base-link>
                        <base-link
                            v-else
                            href="#"
                            @click.prevent="goto_page(page.number)"
                        >
                            {{ page.number }}
                        </base-link>
                    </li>
                    <li
                        :class="{ 'd-invisible': !has_next_page }"
                        class="page-item"
                    >
                        <base-link
                            href="#"
                            @click.prevent="goto_page(nb_pages)"
                        >
                            <open-icon
                                glyph="chevron-right"
                            />
                        </base-link>
                    </li>
                </ul>
                <div
                    :class="{ 'mt-4': (nb_pages <= 1) }"
                    class="text-small text-gray text-center"
                >
                    <span v-if="state.found !== state.total">{{ found_caption }} / </span>{{ total_caption }}
                </div>
            </div>
        </div>

        <video-player-dialog
            :show.sync="watching"
            :name="watching_name"
            :settings="watching_settings"
        />
    </page>
</template>

<script type="text/javascript">
import orderBy from "lodash/orderBy"

import { is_page, tracks } from "@/custom/mixins"
import { is_remote_data_table_source, crud_utilities, profile_utilities } from "@/nibnut/mixins"

import {
    FormDropdown,
    FormInput,
    BaseLink,
    OpenIcon
} from "@/nibnut/components"
import {
    VideoPlayerDialog
} from "@/custom/dialogs"

import default_resource_image from "@/assets/img/logo.png"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities, profile_utilities, tracks],
    components: {
        FormDropdown,
        FormInput,
        BaseLink,
        OpenIcon,
        VideoPlayerDialog
    },
    methods: {
        glyph_for_row (row) {
            if(row.type === this.constants("asset_types", "TYPE_VIDEO").id) return "video"
            if(row.type === this.constants("asset_types", "TYPE_PDF").id) return "file-pdf"
            if(row.type === this.constants("asset_types", "TYPE_TOOL").id) return "drafting-compass"
            return ""
        },
        filter_by (filter_by, filter) {
            this.filtered_by_selection = false
            this.set_state_values({
                page: 1,
                [filter_by]: filter
            })
            this.refresh()
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            return rows
        },
        image_url_for_resource (resource) {
            if(!!resource && !!resource.image_url) return resource.image_url
            return default_resource_image
        },
        watch_video (video) {
            this.watching_name = video.name
            this.watching_settings = video.settings
            this.watching = true
        }
    },
    computed: {
        fields () {
            return ["fieldset::resource-list"]
        },
        labels () {
            const taxonomy = this.constants("taxonomies", "TAXONOMY_LABEL").id
            return orderBy(this.entity_records("tag").filter(tag => tag.taxonomy === taxonomy), "name", "asc")
        },
        types () {
            const audience_role = this.constants("roles", "ROLE_TEACHER").id
            return Object.values(this.constants("asset_types")).filter(asset_type => !asset_type.audience_role || (asset_type.audience_role === audience_role))
        },
        pages () {
            const pages = []

            const visible_around = Math.ceil((this.page_range - 1) / 2)

            let from = this.state.page - visible_around
            if(from <= 0) from = 1

            let to = from + (this.page_range - 1)
            if(to > this.nb_pages) {
                from -= (to - this.nb_pages)
                if(from <= 0) from = 1
                to = this.nb_pages
            }

            if((from - 1) >= 1) pages.push({ number: null, delta: -1 })

            for(let loop = from; loop <= to; loop++) pages.push({ number: loop })

            if((this.nb_pages - to) >= 1) pages.push({ number: null, delta: 1 })

            return pages
        },
        has_prev_page () {
            return this.state.page > 1
        },
        has_next_page () {
            return this.state.page < this.nb_pages
        },
        found_caption () {
            return this.translate("{number} {singular_entity_name} found:::{number} {plural_entity_name} found", { number: this.state.found, singular_entity_name: this.translate(this.entityName, {}, 1).toLowerCase(), plural_entity_name: this.translate(this.entityName, {}, 2).toLowerCase() }, this.state.found || 0)
        },
        total_caption () {
            return this.translate("{number} {singular_entity_name}:::{number} {plural_entity_name}", { number: this.state.total, singular_entity_name: this.translate(this.entityName, {}, 1).toLowerCase(), plural_entity_name: this.translate(this.entityName, {}, 2).toLowerCase() }, this.state.total || 0)
        }
    },
    data () {
        return {
            entity: "asset",
            entityName: "Resource:::Resources",
            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: "type",
                filter: 0,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                role: 5,
                label: null
            },
            watching: false,
            watching_name: "",
            watching_settings: {},

            page_range: 3
        }
    }
}
</script>

<style lang="scss">
#resources-list {
    .pagination {
        justify-content: center;
    }
}
</style>
