<template>
    <div v-if="!!achievement" class="troubleshoot-item">
        <div class="columns">
            <div class="column">
                <h5>{{ achievement.name }}</h5>
            </div>
            <div class="column text-right">
                <h5><open-icon glyph="user-graduate" /> {{ total_student_count | nibnut.number("0,0") }}</h5>
            </div>
        </div>
        <ul>
            <li
                v-for="question in questions"
                :key="question.id"
            >
                <div class="columns">
                    <div class="column">
                        {{ question.label }}
                    </div>
                    <div class="column text-right">
                        <open-icon glyph="user-graduate" /> {{ students_for_question(question).length | nibnut.number("0,0") }}
                    </div>
                </div>
                <div class="mt-2">
                    <base-link
                        v-for="student in students_for_question(question)"
                        :key="student.id"
                        href="#"
                        class="chip"
                        @click.prevent="$emit('replay', student, student_assignment_id(student, question))"
                    >
                        <img :src="student.image_url" class="avatar avatar-sm">
                        {{ student.username }}
                    </base-link>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import {
    OpenIcon,
    BaseLink
} from "@/nibnut/components"

export default {
    name: "TroubleshootItem",
    components: {
        OpenIcon,
        BaseLink
    },
    methods: {
        students_for_question (question) {
            const asset_user_ids = [...new Set(this.answers.filter(record => record.question_id === question.id).map(record => record.asset_user_id))]
            const user_ids = [...new Set(this.entity_records("asset_user", asset_user_ids).map(record => record.user_id))]
            return this.entity_records("group_user").filter(record => (!this.groupId || (record.group_id === this.groupId)) && (user_ids.indexOf(record.user_id) >= 0))
        },
        student_assignment_id (student, question) {
            const asset_user = this.entity_records("asset_user").find(record => (record.asset_id === question.asset_id) && (record.user_id === student.user_id))
            if(asset_user) {
                const answer = this.entity_records("answer").find(record => (record.asset_user_id === asset_user.id) && (record.question_id === question.id))
                if(answer) return answer.assignment_id
            }
            return 0
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        answers () {
            return this.entity_records("answer").filter(record => record.computed_curriculum_item_id === this.achievement.id)
        },
        questions () {
            return this.entity_records("question", this.questionIds)
        },
        total_student_count () {
            return this.questions.reduce((accumulator, question) => {
                return accumulator + this.students_for_question(question).length
            }, 0)
        }
    },
    props: {
        achievement: {
            type: Object,
            required: true
        },
        questionIds: {
            type: Array,
            default () {
                return []
            }
        },
        groupId: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.troubleshoot-item {
    & > .columns {
        padding-top: $unit-2;
        background-color: $primary-color;

        &, h5 {
            color: $light-color;
        }
    }
    ul {
        margin: 0;

        & > li {
            margin: 0;
            padding: $unit-3;
            border-bottom: 1px solid $primary-color-light;
        }
    }
    .chip {
        overflow: visible;

        img {
            height: 1.6rem;
            width: 1.6rem;
            background: $link-color-light;
        }
    }
}
</style>
