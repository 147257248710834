<template>
    <div >
        <h6>{{ $root.translate("Avatars") }}</h6>
        <data-table
            id="avatars-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="record_new"
            @click="record_edit($event)"
        >
            <template
                v-slot:controls
            >
                <div>
                    <form-dropdown
                        id="tag_id"
                        name="tag_id"
                        :value="state.tag_id"
                        :options="all_categories"
                        :empty-label="$root.translate('All Categories')"
                        :required="false"
                        @input="filter_by('tag_id', $event)"
                    />
                </div>
                <div>
                    <form-dropdown
                        id="grade"
                        name="grade"
                        :value="state.grade"
                        :options="raw_grades"
                        :empty-value="-1"
                        :empty-label="$root.translate('All Grades')"
                        :required="false"
                        @input="filter_by('grade', $event)"
                    />
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    <img
                        v-if="!!row.url"
                        :src="row.url"
                        class="mr-2"
                    />
                    {{ row.name }}
                </h6>
                <div>
                    {{ $root.translate("Categories") }}: {{ categories_list_for_row(row) }}
                </div>
                <div class="mt-2">
                    {{ $root.translate("Grades") }}: {{ grades_list_for_row(row) }}
                </div>
                <default-button
                    color="error"
                    :block="true"
                    class="mt-4"
                    @click.prevent="confirm_record_delete(row)"
                >
                    <open-icon glyph="trash" class="mr-2" />
                    {{ $root.translate("Delete") }}
                </default-button>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div
                    v-if="field === 'name'"
                >
                    <img
                        v-if="!!row.url"
                        :src="row.url"
                        class="mr-2"
                    />
                    {{ row.name }}
                </div>
                <div
                    v-else-if="field === 'categories'"
                >
                    {{ categories_list_for_row(row) }}
                </div>
                <div
                    v-else-if="field === 'grades'"
                >
                    {{ grades_list_for_row(row) }}
                </div>
                <div
                    v-else-if="field === '_buttons'"
                    class="text-right"
                >
                    <default-button
                        flavor="link"
                        color="error"
                        size="sm"
                        :title="$root.translate('Delete')"
                        @click.prevent.stop="confirm_record_delete(row)"
                    >
                        <open-icon glyph="trash" />
                    </default-button>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <modal-dialog
            id="avatar-editor"
            :show.sync="editing_record"
        >
            <template v-slot:title>
                <span v-if="!edited_record.id" class="h5">{{ $root.translate("New Avatar") }}</span>
                <span v-else class="h5">{{ edited_record.name }}</span>
            </template>

            <div class="columns">
                <div class="column col-4 col-sm-12 image-holder">
                    <div>
                        <h6>{{ $root.translate("Image") }}</h6>
                        <div
                            class="nibnut-aspect-ratio-box nibnut-ratio-square"
                        >
                            <div>
                                <image-upload-input
                                    v-if="!!edited_record"
                                    name="url"
                                    :url="edited_record.url || held_images.url || ''"
                                    :alt="edited_record.name || ''"
                                    :accepts="upload_accepts('url')"
                                    :uploading="!!uploading.url"
                                    :uploaded="uploaded.url||0"
                                    :error="has_error('url')"
                                    @upload="upload_file_list('url', $event)"
                                    @clear="confirm_clear_file('url')"
                                >
                                    <template v-slot:dragging-title>
                                        <slot name="dragging-title">{{ $root.translate("Drop to upload") }}</slot>
                                    </template>
                                    <template v-slot:empty-title>
                                        <slot name="empty-title">{{ $root.translate("Drag, or...") }}</slot>
                                    </template>
                                    <template v-slot:empty-button-title>
                                        <slot name="empty-button-title">{{ $root.translate("Select") }}</slot>
                                    </template>
                                </image-upload-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column col-8 col-sm-12">
                    <form-input
                        id="name"
                        name="name"
                        v-model="edited_record.name"
                        :required="true"
                        :saving="saving('name')"
                        :error="has_error('name')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Name") }}</template>
                    </form-input>

                    <form-tag-input
                        id="categories"
                        name="categories"
                        v-model="edited_record.categories"
                        :data-source="categories"
                        :ad-hoc="false"
                        :required="true"
                        :show-all="true"
                        @input="save_tag"
                    >
                        <template v-slot:label>{{ $root.translate("Categories") }}</template>
                    </form-tag-input>

                    <form-tag-input
                        id="grades"
                        name="grades"
                        v-model="edited_record.grades"
                        :data-source="grades"
                        :ad-hoc="false"
                        :required="true"
                        :show-all="true"
                        :empty-value="null"
                        @input="save_tag"
                    >
                        <template v-slot:label>{{ $root.translate("Grades") }}</template>
                    </form-tag-input>
                </div>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        v-if="!edited_record.id"
                        class="mr-2"
                        @click.prevent="editing_record=false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        v-if="!edited_record.id"
                        class="ml-2"
                        @click.prevent="record_create"
                    >
                        {{ $root.translate("Create") }}
                    </default-button>
                    <default-button
                        v-if="!!edited_record.id"
                        @click.prevent="save_and_close"
                    >
                        {{ $root.translate("Done") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import is_image_library from "./IsImageLibrary"
import { handles_grades } from "@/custom/mixins"

import { ModalDialog, FormInput, FormTagInput, FormDropdown } from "@/nibnut/components"

export default {
    name: "Avatars",
    mixins: [is_image_library, handles_grades],
    components: {
        ModalDialog,
        FormInput,
        FormTagInput,
        FormDropdown
    },
    methods: {
        save_tag (value, field) {
            this.save(value, field)
        },
        confirm_record_delete (row) {
            this.edited_record = row
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.$root.translate("Do you really want to delete this avatar? Any student using it will be left without an avatar. There is no undo!"),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        categories_list_for_row (row) {
            const tags = this.entity_records("tag")
            if(!tags || !tags.length) return ""
            const names = row.categories.map(category_id => {
                const category = tags.find(tag => tag.id === category_id)
                return category ? category.name : category.id
            })
            return names.join(", ")
        },
        grades_list_for_row (row) {
            const names = row.grades.map(grade_id => {
                return this.grade_by_id(grade_id).abbreviation
            })
            return names.join(", ")
        }
    },
    computed: {
        state_identifier () {
            return "avatars"
        },
        grades () {
            if(!this.edited_record || !this.edited_record.grades) return this.raw_grades
            return this.raw_grades.filter(grade => this.edited_record.grades.indexOf(grade) < 0)
        },
        all_categories () {
            const taxonomy = this.constants("taxonomies", "TAXONOMY_AVATAR_CATEGORY").id
            return orderBy(this.entity_records("tag").filter(tag => tag.taxonomy === taxonomy), "name", "asc")
        },
        categories () {
            if(!this.edited_record || !this.edited_record.grades) return this.all_categories
            return this.all_categories.filter(category => this.edited_record.categories.indexOf(category.id) < 0)
        }
    },
    data () {
        return {
            entityName: "Avatar:::Avatars",

            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                categories: { label: this.translate("Categories"), sort: false },
                grades: { label: this.translate("Grades"), sort: false },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: "type",
                filter: "avatar",
                archived: false,
                search: "",
                total: 0,
                found: 0,
                tag_id: 0,
                grade: -1
            }
        }
    }
}
</script>
