import { is_remote_data_table_source, handles_saving, confirms, handles_uploads } from "@/nibnut/mixins"

import { ImageUploadInput, DefaultButton, OpenIcon } from "@/nibnut/components"

const is_image_library = {
    mixins: [is_remote_data_table_source, handles_saving, confirms, handles_uploads],
    components: {
        ImageUploadInput,
        DefaultButton,
        OpenIcon
    },
    watch: {
        "held.url": "regenerate_held_image"
    },
    methods: {
        record_new () {
            return this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity, reset: true }
            ).then(record => {
                this.edited_record = record
                this.record_id = 0
                this.edited_record.type = this.type
                this.editing_record = true
            }).catch(error => {
                this.$error(error.message)
            })
        },
        record_edit (record) {
            this.edited_record = record
            this.record_id = this.edited_record.id
            this.editing_record = true
        },
        save (value, field) {
            if(this.edited_record) {
                if(this.edited_record[field] !== value) this.edited_record[field] = value
                if(this.edited_record.id) return this.save_field(this.edited_record[field], field)
            }
            return Promise.resolve()
        },
        save_and_close () {
            this.editing_record = false
        },
        record_create () {
            const completed = () => {
                this.editing_record = false
            }

            return this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: this.entity,
                    data: this.edited_record
                }
            ).then(record => {
                this.current_records_ids.push(record.id)

                if(this.held.url) {
                    this.record_id = record.id
                    this.edited_record.id = record.id
                    this.upload_file("url", this.held.url).then(completed).catch(this.receive_error)
                } else completed()
            }).catch(this.receive_error)
        },
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(png|jpe?g)$/i)
            return "image/jpeg,image/png,.jpg,.jpeg,.png"
        },
        upload_data (mode) {
            return {
                entity: this.entity,
                id: this.edited_record.id
            }
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_attached (name, record) {
            this.edited_record.url = record.url
        },
        upload_detached (name, response) {
            this.done_confirming()
        },
        regenerate_held_image () {
            this.generate_held_image("url")
        },
        confirmed () {
            if(this.confirming === "clear-file") this.clear_file()
            else if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.edited_record.id
                    }
                ).then(() => {
                    this.done_confirming()
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        },
        filter_by (filter_by, filter) {
            this.filtered_by_selection = false
            this.set_state_values({
                page: 1,
                [filter_by]: filter
            })
            this.refresh()
        }
    },
    computed: {
        fields () {
            return ["fieldset::default"]
        },
        holding_mode () {
            return !this.edited_record || !this.edited_record.id
        }
    },
    props: {
        type: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(avatar|password)$/),
            default: "avatar"
        }
    },
    data () {
        return {
            entity: "attachment",

            editing_record: false,
            edited_record: {}
        }
    }
}

export default is_image_library
