<template>
    <page
        id="resource-editor"
        :title="page_title"
        :back-navigation-fallback="{ title: $root.translate(entityName, {}, 2), href: { name: 'resource.list' } }"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <div class="page-title">
                <h1>{{ page_title }}</h1>
                <form-toggle-input
                    v-if="!!edited_record"
                    id="publish_at"
                    name="publish_at"
                    type="checkbox"
                    :value="!!edited_record.publish_at"
                    @input="toggle_publish_at"
                >
                    {{ $root.translate("Published") }}
                </form-toggle-input>
            </div>
        </template>

        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
            @submit="create"
        >
            <div class="card">
                <div class="card-body">
                    <div class="columns">
                        <div
                            class="column col-3 col-md-4 col-sm-12"
                        >
                            <div
                                class="nibnut-aspect-ratio-box nibnut-ratio-square"
                            >
                                <div>
                                    <image-upload-input
                                        v-if="!!edited_record"
                                        id="image_url"
                                        name="image_url"
                                        :url="edited_record.image_url || held_images.image_url || ''"
                                        :alt="edited_record.name || ''"
                                        :accepts="upload_accepts('image_url')"
                                        :uploading="!!uploading.image_url"
                                        :uploaded="uploaded.image_url||0"
                                        :error="has_error('image_url')"
                                        @upload="upload_file_list('image_url', $event)"
                                        @clear="confirm_clear_file('image_url')"
                                    />
                                </div>
                            </div>

                            <div
                                v-if="!!edited_record.id"
                                class="text-center mt-8"
                            >
                                <default-button
                                    flavor="link"
                                    color="error"
                                    size="sm"
                                    @click.prevent="confirm_record_delete"
                                >
                                    {{ $root.translate("Delete Resource") }}
                                </default-button>
                            </div>
                        </div>
                        <div
                            :class="{ 'col-9': !has_right_column, 'col-6': has_right_column, 'col-md-4': has_right_column }"
                            class="column col-sm-12"
                        >
                            <div class="columns">
                                <div class="column col-8 col-md-6 col-sm-12">
                                    <form-input
                                        id="name"
                                        name="name"
                                        v-model="edited_record.name"
                                        :required="true"
                                        :disabled="!!edited_record.id"
                                        :saving="saving('name')"
                                        :error="has_error('name')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Name") }}</template>
                                    </form-input>
                                </div>
                                <div class="column col-4 col-md-6 col-sm-12">
                                    <form-select
                                        id="type"
                                        name="type"
                                        v-model="edited_record.type"
                                        :data-source="types"
                                        :required="true"
                                        :disabled="!!edited_record.id"
                                        :saving="saving('type')"
                                        :error="has_error('type')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Type") }}</template>
                                    </form-select>
                                </div>
                            </div>

                            <form-tag-input
                                id="label_ids"
                                name="label_ids"
                                v-model="edited_record.label_ids"
                                data-source="tag"
                                :required="false"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Labels") }}</template>
                            </form-tag-input>

                            <video-input
                                v-if="is_video"
                                id="settings"
                                name="settings"
                                v-model="edited_record.settings"
                                :required="false"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Video Content") }}</template>
                            </video-input>

                            <form-editor
                                id="description"
                                name="description"
                                v-model="edited_record.description"
                                :required="false"
                                size="lg"
                                :full-height="false"
                                class="mt-4"
                                @input="save"
                            >
                                <template v-slot:label>
                                    {{ $root.translate("Description (for teachers)") }}
                                    <div class="text-uppercase mt-1">{{ $root.translate("English") }}</div>
                                </template>
                            </form-editor>

                            <form-editor
                                id="fr_description"
                                name="description"
                                :value="field_translation('description').value"
                                :required="false"
                                size="lg"
                                :full-height="false"
                                class="mt-4"
                                @input="save_field_translation('description', $event)"
                            >
                                <template v-slot:label>
                                    <div class="text-uppercase">{{ $root.translate("French") }}</div>
                                </template>
                            </form-editor>
                        </div>
                        <div
                            v-if="has_right_column"
                            class="column col-3 col-md-4 col-sm-12"
                        >
                            <form-input
                                v-if="is_tool"
                                id="path"
                                name="path"
                                v-model="edited_record.path"
                                :required="true"
                                placeholder="SamAmuse/my-tool"
                                :editable="!edited_record || !edited_record.id"
                                :saving="saving('path')"
                                :error="has_error('path')"
                                @input="save"
                            >
                                <template v-slot:label>
                                    {{ $root.translate("BitBucket Project") }}
                                    <span
                                        v-if="!!edited_record.id && edited_record.is_installing"
                                        class="ml-1 text-gray"
                                    >
                                        {{ $root.translate("Installing...") }}
                                    </span>
                                    <default-button
                                        v-else-if="!!edited_record.id && !!edited_record.path"
                                        flavor="link"
                                        size="sm"
                                        :title="$root.translate('Re-install tool')"
                                        @click.prevent="reinstall"
                                    >
                                        <open-icon
                                            glyph="sync"
                                            :spin="reinstalling"
                                        />
                                    </default-button>
                                </template>
                            </form-input>
                            <upload-input
                                v-else
                                :id="uploadable_name"
                                :name="uploadable_name"
                                :url="edited_record[uploadable_name] ? edited_record[uploadable_name] : (held[uploadable_name] ? 'HELD.pdf' : '')"
                                :alt="edited_record.name"
                                :accepts="upload_accepts(uploadable_name)"
                                :uploading="!!uploading[uploadable_name]"
                                :uploaded="uploaded[uploadable_name]||0"
                                :error="has_error(uploadable_name)"
                                @upload="upload_file_list(uploadable_name, $event)"
                                @clear="confirm_clear_file(uploadable_name)"
                            >
                                <template v-slot:display>
                                    <div
                                        class="empty"
                                    >
                                        <div>
                                            <open-icon :glyph="uploadable_glyph" size="3x" />
                                            <p class="text-small empty-subtitle">
                                                <span v-if="!!held[uploadable_name]">{{ held[uploadable_name].name }}</span>
                                                <span v-else-if="edited_record[uploadable]">{{ edited_record[uploadable] }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </upload-input>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!edited_record.id"
                    class="card-footer text-center"
                >
                    <default-button
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="$router.go(-1)"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="!!creating"
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="create"
                    >
                        {{ $root.translate("Save") }}
                    </default-button>
                </div>
            </div>
        </base-form>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor, has_translatable_fields } from "@/custom/mixins"
import { handles_uploads } from "@/nibnut/mixins"

import {
    BaseForm,
    FormToggleInput,
    FormSelect,
    FormInput,
    FormTagInput,
    FormEditor,
    ImageUploadInput,
    UploadInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    VideoInput
} from "@/custom/components"

export default {
    name: "ResourceEdit",
    mixins: [is_record_editor, has_translatable_fields, handles_uploads],
    components: {
        BaseForm,
        FormToggleInput,
        FormSelect,
        FormInput,
        FormTagInput,
        FormEditor,
        ImageUploadInput,
        UploadInput,
        DefaultButton,
        OpenIcon,
        VideoInput
    },
    watch: {
        "held.image_url": "regenerate_held_image"
    },
    methods: {
        shell_record_context () {
            return { audience_role: this.constants("roles", "ROLE_TEACHER").id }
        },
        create () {
            this.creating = true
            return this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data: this.edited_record
            }).then(record => {
                const completed = () => {
                    this.creating = false
                    this.$router.replace({ name: "resource.list" })
                }
                const upload_image = () => {
                    if(this.held.image_url) {
                        this.record_id = record.id
                        this.upload_file("image_url", this.held.image_url).then(() => {
                            upload_content()
                        }).catch(error => {
                            this.receive_error(error)
                            this.creating = false
                        })
                    } else upload_content()
                }
                const upload_content = () => {
                    if((record.type === this.constants("asset_types", "TYPE_ZIP").id) && !!this.held.zip_url) {
                        this.record_id = record.id
                        this.upload_file("zip_url", this.held.zip_url).then(() => {
                            completed()
                        }).catch(error => {
                            this.receive_error(error)
                            this.creating = false
                        })
                    } else if((record.type === this.constants("asset_types", "TYPE_PDF").id) && !!this.held.pdf_url) {
                        this.record_id = record.id
                        this.upload_file("pdf_url", this.held.pdf_url).then(() => {
                            completed()
                        }).catch(error => {
                            this.receive_error(error)
                            this.creating = false
                        })
                    } else completed()
                }

                this.record_id = record.id
                const translation = this.field_translation_for_classname(this.classname, 0, "description")
                if(translation && translation.value) {
                    this.save_field_translation("description", translation.value).then(() => {
                        upload_image()
                    })
                } else upload_image() // else upload_pdf()
            }).catch(error => {
                this.receive_error(error)
                this.creating = false
            })
        },
        toggle_publish_at () {
            if(this.edited_record.publish_at) this.save(null, "publish_at")
            else this.save(this.$moment().format("YYYY-MM-DD HH:mm:ss"), "publish_at")
        },
        upload_accepts (name, filename = null) {
            if(name === "zip_url") {
                if(filename) return !!filename.match(/\.zip$/i)
                return "application/zip,.zip"
            }
            if(name === "pdf_url") {
                if(filename) return !!filename.match(/\.pdf$/i)
                return "application/pdf,.pdf"
            }
            if(filename) return !!filename.match(/\.(png|jpe?g)$/i)
            return "image/jpeg,image/png,.jpg,.jpeg,.png"
        },
        upload_data (mode) {
            return {
                entity: this.entity,
                id: this.record_id
            }
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detached (name, response) {
            this.done_confirming()
        },
        regenerate_held_image () {
            this.generate_held_image("image_url")
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.$root.translate("Do you really want to delete this resource? It will no longer be available to teachers. There is no undo!"),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "clear-file") this.clear_file()
            else if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            this.$router.push({ name: "resource.list" })
        }
    },
    computed: {
        types () {
            if(!this.edited_record || !this.edited_record.audience_role) return []
            return Object.values(this.constants("asset_types")).filter(asset_type => !asset_type.audience_role || (asset_type.audience_role === this.edited_record.audience_role))
        },
        holding_mode () {
            return !this.record_id
        },
        is_video () {
            if(this.edited_record) return (this.edited_record.type === this.constants("asset_types", "TYPE_VIDEO").id)
            return false
        },
        is_tool () {
            if(this.edited_record) return (this.edited_record.type === this.constants("asset_types", "TYPE_TOOL").id)
            return false
        },
        has_right_column () {
            if(this.edited_record) return (this.edited_record.type === this.constants("asset_types", "TYPE_PDF").id) || (this.edited_record.type === this.constants("asset_types", "TYPE_ZIP").id) || (this.edited_record.type === this.constants("asset_types", "TYPE_TOOL").id)
            return false
        },
        uploadable_name () {
            if(this.edited_record && (this.edited_record.type === this.constants("asset_types", "TYPE_ZIP").id)) return "zip_url"
            return "pdf_url"
        },
        uploadable_glyph () {
            if(this.edited_record && (this.edited_record.type === this.constants("asset_types", "TYPE_ZIP").id)) return "file-archive"
            return "file-pdf"
        },
        uploadable () {
            if(this.edited_record && (this.edited_record.type === this.constants("asset_types", "TYPE_ZIP").id)) return "zip_name"
            return "pdf_name"
        }
    },
    data () {
        return {
            entity: "asset",
            entityName: "Resource:::Resources",
            classname: "App\\Asset",
            creating: false
        }
    }
}
</script>

<style lang="scss">
#resource-editor {
    & > .page-title {
        display: flex;
        align-items: center;

        & > h1 {
            flex: 1 1 auto;
        }
        & > .form-group {
            flex: 0 1 auto;
        }
    }
    .nibnut-document-uploader, .nibnut-document-uploader .empty, .nibnut-document-uploader .error, .nibnut-document-uploader .content-holder {
        height: auto;
    }
}
</style>
