<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("Institutions") }}</div>
        </div>
        <div class="card-body">
            <data-table
                id="institutions-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="true"
                :export-url="export_url"
                @sort="sort_by"
                @search="search"
                @page="goto_page"
                @add="institution_new"
                @click="institution_edit($event)"
            >
                <template
                    v-slot:controls
                >
                    <div>
                        <form-dropdown
                            id="filter"
                            name="filter"
                            :value="state.filter"
                            :options="provinces"
                            empty-value=""
                            :empty-label="$root.translate('Province')"
                            :required="false"
                            @input="filter('state', $event)"
                        />
                    </div>
                    <div>
                        <form-dropdown
                            id="language-filter"
                            name="language-filter"
                            :value="state.language"
                            :options="languages"
                            empty-value=""
                            :empty-label="$root.translate('Language')"
                            :required="false"
                            @input="filter_by('language', $event)"
                        />
                    </div>
                </template>
                <template
                    v-slot:summary="{ row }"
                >
                    <h6>{{ row.name }}</h6>
                    <div>{{ row.city }} ({{ row.state }})</div>
                    <default-button
                        color="error"
                        :block="true"
                        class="mt-4"
                        @click.prevent="confirm_record_delete(row)"
                    >
                        <open-icon glyph="trash" class="mr-2" />
                        {{ $root.translate("Delete") }}
                    </default-button>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div
                        v-if="field === '_buttons'"
                        class="text-right"
                    >
                        <default-button
                            flavor="link"
                            color="error"
                            size="sm"
                            :title="$root.translate('Delete')"
                            @click.prevent.stop="confirm_record_delete(row)"
                        >
                            <open-icon glyph="trash" />
                        </default-button>
                    </div>
                    <span v-else-if="field === 'city'">{{ row.city }} ({{ row.state }})</span>
                    <span v-else>{{ row[field] }}</span>
                </template>
            </data-table>

            <modal-dialog
                id="institution-editor"
                :show.sync="editing_institution"
                class="modal-lg"
            >
                <template v-slot:title>
                    <span v-if="!edited_institution.id" class="h5">{{ $root.translate("New Institution") }}</span>
                    <span v-else class="h5">{{ edited_institution.name }} ({{ edited_institution.state }})</span>
                </template>

                <div class="columns">
                    <div class="column col-6 col-md-12">
                        <form-input
                            id="name"
                            name="name"
                            v-model="edited_institution.name"
                            :required="true"
                            :saving="saving('name')"
                            :error="has_error('name')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Name") }}</template>
                        </form-input>

                        <form-input
                            id="district"
                            name="district"
                            v-model="edited_institution.district"
                            :required="false"
                            :saving="saving('district')"
                            :error="has_error('district')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Division/District/Authority") }}</template>
                        </form-input>

                        <address-input
                            id="address"
                            name="address"
                            v-model="edited_institution"
                            :required="true"
                            @input="save_data_for_record_id(entity, edited_institution.id, $event)"
                        >
                            <template v-slot:label>{{ $root.translate("Address") }}</template>
                        </address-input>

                        <form-input
                            id="website"
                            name="website"
                            v-model="edited_institution.website"
                            :required="false"
                            :saving="saving('website')"
                            :error="has_error('website')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Website (URL)") }}</template>
                        </form-input>
                        <div class="columns">
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="email"
                                    name="email"
                                    v-model="edited_institution.email"
                                    :required="false"
                                    :saving="saving('email')"
                                    :error="has_error('email')"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ $root.translate("Email") }}</template>
                                </form-input>
                            </div>
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="phone"
                                    name="phone"
                                    v-model="edited_institution.phone"
                                    :required="false"
                                    :saving="saving('phone')"
                                    :error="has_error('phone')"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ $root.translate("Phone") }}</template>
                                </form-input>
                            </div>
                        </div>

                        <form-textbox
                            id="notes"
                            name="notes"
                            v-model="edited_institution.notes"
                            :required="false"
                            :saving="saving('notes')"
                            :error="has_error('notes')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Notes (internal use)") }}</template>
                        </form-textbox>
                    </div>
                    <div class="column col-6 col-md-12">
                        <h6>{{ $root.translate("Grades") }}</h6>
                        <div class="columns">
                            <div
                                v-for="grade in raw_grades"
                                :key="grade.id"
                                class="column col-4 col-sm-6"
                            >
                                <form-toggle-input
                                    :id="`grade_${grade.id}`"
                                    :name="`grade_${grade.id}`"
                                    type="checkbox"
                                    :value="edited_institution[`grade_${grade.id}`]"
                                    @input="save"
                                >
                                    {{ grade.name }}
                                </form-toggle-input>
                            </div>
                        </div>

                        <h6 class="mt-4">{{ $root.translate("Languages") }}</h6>
                        <div class="columns">
                            <div
                                v-for="language in languages"
                                :key="language.id"
                                class="column col-4 col-sm-6"
                            >
                                <form-toggle-input
                                    :id="language.id"
                                    :name="language.id"
                                    type="checkbox"
                                    :value="edited_institution[language.id]"
                                    @input="save"
                                >
                                    {{ language.name }}
                                </form-toggle-input>
                            </div>
                        </div>

                        <form-input
                            id="programs"
                            name="programs"
                            v-model="edited_institution.programs"
                            :required="false"
                            :saving="saving('programs')"
                            :error="has_error('programs')"
                            class="mt-4"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Programs") }}</template>
                        </form-input>
                    </div>
                </div>

                <template v-slot:footer>
                    <div class="text-center">
                        <default-button
                            v-if="!edited_institution.id"
                            class="mr-2"
                            @click.prevent="editing_institution=false"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                        <default-button
                            v-if="!edited_institution.id"
                            class="ml-2"
                            @click.prevent="institution_create"
                        >
                            {{ $root.translate("Create") }}
                        </default-button>
                        <default-button
                            v-if="!!edited_institution.id"
                            @click.prevent="editing_institution=false"
                        >
                            {{ $root.translate("Done") }}
                        </default-button>
                    </div>
                </template>
            </modal-dialog>

            <confirmation
                v-if="!!confirming"
                v-bind="confirmation_props"
                @cancel="done_confirming"
                @confirmed="confirmed"
            />
        </div>
    </div>
</template>

<script>
import provinces from "@/custom/provinces.js"

import { is_remote_data_table_source, handles_saving, misc_utilities, confirms } from "@/nibnut/mixins"
import { handles_grades } from "@/custom/mixins"

import { ModalDialog, FormDropdown, FormInput, AddressInput, FormTextbox, FormToggleInput, DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "Institutions",
    mixins: [is_remote_data_table_source, handles_saving, misc_utilities, confirms, handles_grades],
    components: {
        ModalDialog,
        FormDropdown,
        FormInput,
        AddressInput,
        FormTextbox,
        FormToggleInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        filter_by (filter_by, filter) {
            this.filtered_by_selection = false
            this.set_state_values({
                page: 1,
                [filter_by]: filter
            })
            this.refresh()
        },
        confirm_record_delete (row) {
            this.edited_institution = row
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: `${this.edited_institution.name} (${this.edited_institution.state})` }),
                    message: this.$root.translate("Do you really want to delete this institution? All related data will also be deleted: licenses, students, analytics data, etc... There is no undo!"),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.edited_institution.id
                    }
                ).then(() => {
                    this.done_confirming()
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        },
        institution_new () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity, reset: true }
            ).then(record => {
                this.edited_institution = record
                this.editing_institution = true
            }).catch(error => {
                this.$error(error.message)
            })
        },
        institution_edit (institution) {
            this.edited_institution = institution
            this.record_id = this.edited_institution.id
            this.editing_institution = true
        },
        save (value, field) {
            if(this.edited_institution) {
                if(this.edited_institution[field] !== value) this.edited_institution[field] = value
                if(this.edited_institution.id) return this.save_field(this.edited_institution[field], field)
            }
            return Promise.resolve()
        },
        institution_create () {
            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: "institution",
                    data: this.edited_institution
                }
            ).then(record => {
                this.current_records_ids.push(record.id)
                this.edited_institution = record
            }).catch(this.receive_error)
        }
    },
    computed: {
        state_identifier () {
            return "institutions-list"
        },
        fields () {
            return ["fieldset::default"]
        },
        provinces () {
            return provinces.map(province => {
                return { id: province.id, name: province.id }
            })
        },
        languages () {
            return Object.values(this.constants("languages"))
        }
    },
    data () {
        return {
            entity: "institution",
            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                city: { label: this.translate("City"), sort: null, type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                language: ""
            },

            editing_institution: false,
            edited_institution: {}
        }
    }
}
</script>
