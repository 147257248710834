<template>
    <div class="card count-up-card">
        <open-icon v-if="glyph" :glyph="glyph" />
        <div class="card-header">
            <div class="card-title">
                <span v-if="asTime">
                    <span v-if="total_hours">{{ hour_value }} h </span>
                    <span v-if="total_minutes">{{ minute_value }} min </span>
                    <span v-else>&lt; 1 min </span>
                </span>
                <span v-else>{{ number_value }}</span>
            </div>
            <div class="card-subtitle text-gray">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import {
    OpenIcon
} from "@/nibnut/components"

const TWEEN = require("@tweenjs/tween.js")

export default {
    name: "CountUpCard",
    mounted () {
        this.tween_group = new TWEEN.Group()

        const animate = (time) => {
            requestAnimationFrame(animate)
            this.tween_group.update(time)
        }
        requestAnimationFrame(animate)

        this.reset()
    },
    watch: {
        from: "reset",
        to: "reset"
    },
    components: {
        OpenIcon
    },
    methods: {
        reset () {
            if(this.tween) this.tween.stop()
            if(this.to) {
                this.value = this.from
                const tween_value = { value: this.value }
                this.tween = new TWEEN.Tween(tween_value, this.tween_group)
                    .to({ value: this.to }, this.duration)
                    .easing(TWEEN.Easing.Cubic.InOut)
                    .onUpdate(() => {
                        this.value = Math.round(tween_value.value)
                    })
                    .start()
            }
        }
    },
    computed: {
        number_value () {
            return this.$numeral(this.value).format("0,0")
        },
        total_hours () {
            return Math.floor((this.to / 1000 / 60 / 60) % 24)
        },
        total_minutes () {
            return Math.floor((this.to / 1000 / 60) % 60)
        },
        hour_value () {
            const hours = Math.floor((this.value / 1000 / 60 / 60) % 24)
            return this.$numeral(hours).format("0,0")
        },
        minute_value () {
            return Math.floor((this.value / 1000 / 60) % 60)
        }
    },
    props: {
        from: {
            type: Number, // in milliseconds
            default: 0
        },
        to: {
            type: Number,
            required: true
        },
        asTime: {
            type: Boolean,
            default: false
        },
        duration: {
            type: Number,
            default: 3000
        },
        glyph: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            tween_group: null,
            tween: null,
            value: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.count-up-card {
    position: relative;
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
    overflow: hidden;

    & > .card-header {
        & > .card-title {
            font-size: 3.5rem;
        }
    }
    & > i.las {
        position: absolute;
        left: -3rem;
        top: 50%;
        font-size: 10rem;
        font-weight: bold;
        transform: translate(0, -50%);
        color: rgba($primary-color, 0.3);
    }
}
</style>
