<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("Tax Rates") }}</div>
        </div>
        <div class="card-body">
            <data-table
                id="tax-rates-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="true"
                @sort="sort_by"
                @search="search"
                @page="goto_page"
                @add="tax_rate_new"
                @click="tax_rate_edit($event)"
            >
                <template
                    v-slot:summary="{ row }"
                >
                    <h6>{{ row.name }} - {{ row.percentage | nibnut.number("0,0.000") }}&nbsp;%</h6>
                    <div class="text-small">
                        <span
                            v-for="province in row.provinces"
                            :key="province"
                            :data-tooltip="tooltip_for_province(province)"
                            class="tooltip mr-2"
                        >
                            {{ province }}
                        </span>
                    </div>
                    <default-button
                        :flavor="row.deleted_at ? 'normal' : 'link'"
                        color="error"
                        :block="true"
                        :title="row.deleted_at ? $root.translate('Re-activate') : $root.translate('De-activate')"
                        @click.prevent="row_toggle_status(row)"
                    >
                        <span v-if="row.deleted_at" >
                            <open-icon glyph="trash-restore" /> {{ $root.translate('Re-activate') }}
                        </span>
                        <span v-else>
                            <open-icon glyph="trash" /> {{ $root.translate('De-activate') }}
                        </span>
                    </default-button>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div
                        v-if="field === 'name'"
                        :class="{ 'text-strikethrough': !!row.deleted_at }"
                    >
                        {{ row.name }}
                        <div class="text-small">
                            <span
                                v-for="province in row.provinces"
                                :key="province"
                                :data-tooltip="tooltip_for_province(province)"
                                class="tooltip mr-2"
                            >
                                {{ province }}
                            </span>
                        </div>
                    </div>
                    <span
                        v-else-if="field === 'percentage'"
                        :class="{ 'text-strikethrough': !!row.deleted_at }"
                    >
                        {{ row.percentage | nibnut.number("0,0.000") }}&nbsp;%
                    </span>
                    <div
                        v-else-if="field === '_buttons'"
                        class="text-right"
                    >
                        <default-button
                            flavor="link"
                            color="error"
                            size="sm"
                            :title="row.deleted_at ? $root.translate('Re-activate') : $root.translate('De-activate')"
                            @click.prevent.stop="row_toggle_status(row)"
                        >
                            <open-icon v-if="row.deleted_at" glyph="trash-restore" />
                            <open-icon v-else glyph="trash" />
                        </default-button>
                    </div>
                    <span
                        v-else
                        :class="{ 'text-strikethrough': !!row.deleted_at }"
                    >
                        {{ row[field] }}
                    </span>
                </template>
            </data-table>

            <modal-dialog
                id="tax-rate-editor"
                :show.sync="editing_tax_rate"
            >
                <template v-slot:title>
                    <span v-if="!edited_tax_rate.id" class="h5">{{ $root.translate("New Tax Rate") }}</span>
                    <span v-else class="h5">{{ edited_tax_rate.name }}</span>
                </template>

                <div class="columns">
                    <div class="column col-4 col-md-4 col-sm-12">
                        <form-input
                            id="name"
                            name="name"
                            v-model="edited_tax_rate.name"
                            :required="true"
                            :saving="saving('name')"
                            :error="has_error('name')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Name") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-4 col-md-4 col-sm-12">
                        <form-input
                            v-if="!!edited_tax_rate.id"
                            id="fr_name"
                            name="name"
                            :value="field_translation_for_classname(classname, edited_tax_rate.id, 'name').value"
                            :required="false"
                            @input="save_field_translation_for_classname(classname, edited_tax_rate.id, 'name', $event)"
                        >
                            <template v-slot:label>{{ $root.translate("Name (FR)") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-4 col-md-34 col-sm-12">
                        <form-input
                            id="percentage"
                            name="percentage"
                            type="number"
                            :min="0"
                            :max="100"
                            :step="0.1"
                            glyph="percent"
                            v-model="edited_tax_rate.percentage"
                            :required="true"
                            :editable="!edited_tax_rate.id"
                            :saving="saving('percentage')"
                            :error="has_error('percentage')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Percentage") }}</template>
                            <template v-slot:empty-value>{{ edited_tax_rate.percentage | nibnut.number("0,0.000") }}&nbsp;%</template>
                        </form-input>
                    </div>
                </div>
                <form-tag-input
                    id="provinces"
                    name="provinces"
                    v-model="edited_tax_rate.provinces"
                    :data-source="provinces"
                    :required="false"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("Provinces") }}</template>
                </form-tag-input>

                <template v-slot:footer>
                    <div class="text-center">
                        <default-button
                            v-if="!edited_tax_rate.id"
                            class="mr-2"
                            @click.prevent="editing_tax_rate=false"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                        <default-button
                            v-if="!edited_tax_rate.id"
                            class="ml-2"
                            @click.prevent="tax_rate_create"
                        >
                            {{ $root.translate("Create") }}
                        </default-button>
                        <default-button
                            v-if="!!edited_tax_rate.id"
                            @click.prevent="save_and_close"
                        >
                            {{ $root.translate("Done") }}
                        </default-button>
                    </div>
                </template>
            </modal-dialog>
        </div>
    </div>
</template>

<script>
import provinces from "@/custom/provinces.js"

import { is_remote_data_table_source, handles_saving, misc_utilities } from "@/nibnut/mixins"
import { has_translatable_fields } from "@/custom/mixins"

import { ModalDialog, FormInput, FormTagInput, DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "TaxRates",
    mixins: [is_remote_data_table_source, handles_saving, misc_utilities, has_translatable_fields],
    components: {
        ModalDialog,
        FormInput,
        FormTagInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        tax_rate_new () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity, reset: true }
            ).then(record => {
                this.edited_tax_rate = record
                this.editing_tax_rate = true
            }).catch(error => {
                this.$error(error.message)
            })
        },
        tax_rate_edit (tax_rate) {
            this.edited_tax_rate = tax_rate
            this.editing_tax_rate = true
        },
        save (value, field) {
            if(this.edited_tax_rate) {
                if(this.edited_tax_rate[field] !== value) this.edited_tax_rate[field] = value
                if(this.edited_tax_rate.id) return this.save_field_for_record_id(this.entity, this.edited_tax_rate.id, this.edited_tax_rate[field], field)
            }
            return Promise.resolve()
        },
        tax_rate_create () {
            const { deleted_at, stripe_id, ...data } = this.edited_tax_rate
            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: this.entity,
                    data
                }
            ).then(record => {
                this.current_records_ids.push(record.id)
                this.editing_tax_rate = false
            }).catch(this.receive_error)
        },
        save_and_close () {
            this.editing_tax_rate = false
        },
        row_toggle_status (row) {
            this.$store.dispatch(
                row.deleted_at ? "RECORD_RESTORE" : "RECORD_DELETE",
                { entity: this.entity, id: row.id }
            ).catch(this.receive_error)
        },
        tooltip_for_province (province) {
            const rates = []
            this.rows.forEach(rate => {
                if(rate.provinces.indexOf(province) >= 0) rates.push(`${this.nibnut_filter("nibnut.number", [rate.percentage, "0,0.000"])} % ${rate.name}`)
            })
            return `${province}: ${rates.join(" + ")}`
        }
    },
    computed: {
        state_identifier () {
            return "tax-rates-list"
        },
        fields () {
            return ["fieldset::default"]
        },
        provinces () {
            return provinces
        }
    },
    data () {
        return {
            entity: "tax_rate",
            classname: "App\\TaxRate",
            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                percentage: { label: "%", sort: null, type: "numeric" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 3,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: true,
                search: "",
                total: 0,
                found: 0
            },

            editing_tax_rate: false,
            edited_tax_rate: {}
        }
    }
}
</script>

<style lang="scss">
.table.tax-rates {
    thead {
        vertical-align: top;
    }

    td {
        .form-input {
            width: 100px;
        }

        &:first-child {
            width: 100%;
        }
    }
}
</style>
