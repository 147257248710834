<template>
    <div v-if="!!app_context" class="form-group">
        <label class="form-label text-bold">BitBucket</label>
        <label v-if="!!app_context.settings.bitbucket_accessible" class="form-label text-success">{{ $root.translate("Connected") }}</label>
        <base-link
            v-else
            :href="app_context.settings.bitbucket_auth_url"
            :title="$root.translate('Connect to your BitBucket Account')"
            class="btn"
        >
            {{ $root.translate("Connect") }}
        </base-link>
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import { BaseLink } from "@/nibnut/components"

export default {
    name: "BitbucketConnector",
    components: {
        BaseLink
    },
    computed: {
        ...mapState(["app_context"])
    }
}
</script>
