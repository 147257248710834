<template>
    <ul
        v-if="!!curriculum_items.length"
        class="curriculum-item"
    >
        <li
            v-for="curriculum_item in curriculum_items"
            :key="curriculum_item.id"
        >
            <div class="item-data">
                <form-input
                    :id="`name-${curriculum_item.id}`"
                    name="name"
                    v-model="curriculum_item.name"
                    :required="true"
                    :saving="saving('name')"
                    :error="has_error('name')"
                    @input="save_field_for_record_id('curriculum_item', curriculum_item.id, $event, 'name')"
                />
                <default-button
                    flavor="link"
                    color="error"
                    :title="$root.translate('Delete')"
                    class="ml-2"
                    @click.prevent="$emit('delete', curriculum_item)"
                >
                    <open-icon glyph="trash" />
                </default-button>
                <default-button
                    flavor="link"
                    :title="$root.translate('Add item to {name}', { name: curriculum_item.name })"
                    class="ml-2"
                    @click.prevent="$emit('create', curriculum_item)"
                >
                    <open-icon glyph="plus" />
                </default-button>
            </div>
            <div class="item-data">
                <form-toggle-input
                    :id="`type-${curriculum_item.id}`"
                    name="type"
                    type="checkbox"
                    :value="curriculum_item.type === constants('curriculum_item_types', 'TYPE_ACHIEVEMENT').id"
                    size="sm"
                    class="text-gray"
                    @input="toggle_item_type(curriculum_item)"
                >
                    {{ $root.translate("Achievement") }}
                </form-toggle-input>
            </div>

            <curriculum-item
                :parent-id="curriculum_item.id"
                :curriculum-id="curriculumId"
                :subject-tag-id="subjectTagId"
                :grade-id="gradeId"
                v-on="$listeners"
            />
        </li>
    </ul>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { handles_saving, misc_utilities } from "@/nibnut/mixins"

import { FormInput, FormToggleInput, DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "CurriculumItem",
    mixins: [handles_saving, misc_utilities],
    components: {
        FormInput,
        FormToggleInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        toggle_item_type (curriculum_item) {
            this.save_field_for_record_id("curriculum_item", curriculum_item.id, (curriculum_item.type === this.constants("curriculum_item_types", "TYPE_ACHIEVEMENT").id) ? this.constants("curriculum_item_types", "TYPE_OUTCOME").id : this.constants("curriculum_item_types", "TYPE_ACHIEVEMENT").id, "type")
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        raw_curriculum_items () {
            return this.entity_records("curriculum_item").filter(curriculum_item => (curriculum_item.subject_tag_id === this.subjectTagId) && (curriculum_item.grade === this.gradeId) && (curriculum_item.curriculum_id === this.curriculumId) && (curriculum_item.parent_id === this.parentId))
        },
        curriculum_items () {
            return orderBy(this.raw_curriculum_items, "name", "asc")
        }
    },
    props: {
        parentId: {
            type: Number,
            required: true
        },
        curriculumId: {
            type: Number,
            required: true
        },
        subjectTagId: {
            type: Number,
            required: true
        },
        gradeId: {
            type: Number,
            required: true
        }
    }
}
</script>

<style lang="scss">
ul.curriculum-item {
    list-style: none;

    .item-data {
        display: flex;

        & > .form-group {
            flex: 1 0 auto;
        }
        & > .btn {
            flex: 0 0 auto;
        }
    }
}
</style>
