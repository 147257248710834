import { handles_saving, profile_utilities, ui_utilities } from "@/nibnut/mixins"

const is_settings_panel = {
    mixins: [handles_saving, profile_utilities, ui_utilities],
    mounted () {
        this.reset_record_id()
    },
    watch: {
        record: "reset_record_id"
    },
    methods: {
        reset_record_id () {
            this.record_id = this.record ? this.record.id : 0
        },
        save (value, field) {
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.record.id) return this.save_field(this.record[field], field)
            }
            return Promise.resolve()
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "setting",
            record_id: 0
        }
    }
}

export default is_settings_panel
