<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("Curricula") }}</div>
        </div>
        <div class="card-body">
            <data-table
                id="curricula-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="true"
                :export-url="export_url"
                @sort="sort_by"
                @search="search"
                @page="goto_page"
                @add="curriculum_new"
                @click="curriculum_edit($event)"
            >
                <template
                    v-slot:controls
                >
                    <div>
                        <form-dropdown
                            id="filter"
                            name="filter"
                            :value="state.filter"
                            :options="provinces"
                            empty-value=""
                            :empty-label="$root.translate('Any Province')"
                            :required="false"
                            @input="filter('province', $event)"
                        />
                    </div>
                </template>
                <template
                    v-slot:summary="{ row }"
                >
                    <h6>{{ row.name }} ({{ row.province }})</h6>
                    <default-button
                        color="error"
                        :block="true"
                        class="mt-4"
                        @click.prevent="confirm_record_delete(row)"
                    >
                        <open-icon glyph="trash" class="mr-2" />
                        {{ $root.translate("Delete") }}
                    </default-button>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div
                        v-if="field === '_buttons'"
                        class="text-right"
                    >
                        <default-button
                            flavor="link"
                            color="error"
                            size="sm"
                            :title="$root.translate('Delete')"
                            @click.prevent.stop="confirm_record_delete(row)"
                        >
                            <open-icon glyph="trash" />
                        </default-button>
                    </div>
                    <span v-else>{{ row[field] }}</span>
                </template>
            </data-table>

            <modal-dialog
                id="curriculum-editor"
                :show.sync="editing_curriculum"
                :class="{ 'modal-lg': !!edited_curriculum.id }"
            >
                <template v-slot:title>
                    <span v-if="!edited_curriculum.id" class="h5">{{ $root.translate("New Curriculum") }}</span>
                    <span v-else class="h5">{{ edited_curriculum.name }} ({{ edited_curriculum.province }})</span>
                </template>

                <div class="columns">
                    <div class="column col-5 col-sm-6">
                        <form-input
                            id="name"
                            name="name"
                            v-model="edited_curriculum.name"
                            :required="true"
                            :saving="saving('name')"
                            :error="has_error('name')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Name") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-5 col-sm-6">
                        <form-input
                            id="nom"
                            name="nom"
                            v-model="edited_curriculum.nom"
                            :required="true"
                            :saving="saving('nom')"
                            :error="has_error('nom')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Nom") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-2 col-sm-6">
                        <form-dropdown
                            id="province"
                            name="province"
                            v-model="edited_curriculum.province"
                            :options="provinces"
                            :required="true"
                            :saving="saving('province')"
                            :error="has_error('province')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Province") }}</template>
                        </form-dropdown>
                    </div>
                </div>

                <form-dropdown
                    v-if="!!edited_curriculum && !!edited_curriculum.id"
                    id="current_subject_tag_id"
                    name="current_subject_tag_id"
                    v-model="current_subject_tag_id"
                    :options="subjects"
                    :required="true"
                    class="mt-8"
                />
                <ul
                    v-if="!!edited_curriculum && !!edited_curriculum.id"
                    class="tab tab-block mt-4"
                >
                    <li
                        v-for="grade in raw_grades"
                        :key="grade.id"
                        :class="{ active: (current_grade_id === grade.id) }"
                        class="tab-item"
                    >
                        <base-link
                            href="#"
                            @click.prevent="current_grade_id = grade.id"
                        >
                            {{ grade.abbreviation }}
                        </base-link>
                    </li>
                </ul>

                <curriculum-item
                    v-if="!!edited_curriculum && !!edited_curriculum.id"
                    :parent-id="0"
                    :curriculum-id="edited_curriculum.id"
                    :subject-tag-id="current_subject_tag_id"
                    :grade-id="current_grade_id"
                    @create="curriculum_item_new"
                    @delete="confirm_delete_curriculum_item"
                />
                <default-button
                    v-if="!!edited_curriculum && !!edited_curriculum.id"
                    flavour="link"
                    :block="true"
                    class="mt-4"
                    @click.prevent="curriculum_item_new(null)"
                >
                    <open-icon glyph="plus" class="mr-2" />
                    {{ $root.translate("New Root Curriculum Item") }}
                </default-button>

                <template v-slot:footer>
                    <div class="text-center">
                        <default-button
                            v-if="!edited_curriculum.id"
                            class="mr-2"
                            @click.prevent="editing_curriculum=false"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                        <default-button
                            v-if="!edited_curriculum.id"
                            class="ml-2"
                            @click.prevent="curriculum_create"
                        >
                            {{ $root.translate("Save & Setup Content") }}
                        </default-button>
                        <default-button
                            v-if="!!edited_curriculum.id"
                            @click.prevent="editing_curriculum=false"
                        >
                            {{ $root.translate("Done") }}
                        </default-button>
                    </div>
                </template>
            </modal-dialog>

            <modal-dialog
                id="curriculum-item-editor"
                :show.sync="editing_curriculum_item"
            >
                <template v-slot:title>
                    <span v-if="!edited_curriculum_item._parent" class="h5">{{ $root.translate("New Root Curriculum Item") }})</span>
                    <span v-else class="h5">{{ edited_curriculum_item._parent.name }}</span>
                    <form-toggle-input
                        id="type"
                        name="type"
                        type="checkbox"
                        :value="edited_curriculum_item.type === constants('curriculum_item_types', 'TYPE_ACHIEVEMENT').id"
                        class="float-right"
                        @input="toggle_item_type"
                    >
                        {{ $root.translate("Achievement") }}
                    </form-toggle-input>
                </template>

                <div class="columns">
                    <div class="column col-6 col-sm-12">
                        <form-input
                            id="curriculum-item-name"
                            name="name"
                            v-model="edited_curriculum_item.name"
                            :required="true"
                            :saving="saving('name')"
                            :error="has_error('name')"
                            @input="save_curriculum_item"
                        >
                            <template v-slot:label>{{ $root.translate("Name") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-sm-12">
                        <form-input
                            id="curriculum-item-name"
                            name="nom"
                            v-model="edited_curriculum_item.nom"
                            :required="true"
                            :saving="saving('nom')"
                            :error="has_error('nom')"
                            @input="save_curriculum_item"
                        >
                            <template v-slot:label>{{ $root.translate("Nom") }}</template>
                        </form-input>
                    </div>
                </div>

                <template v-slot:footer>
                    <div class="text-center">
                        <default-button
                            class="mr-2"
                            @click.prevent="editing_curriculum_item=false"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                        <default-button
                            class="ml-2"
                            @click.prevent="curriculum_item_create"
                        >
                            {{ $root.translate("Add") }}
                        </default-button>
                    </div>
                </template>
            </modal-dialog>

            <confirmation
                v-if="!!confirming"
                v-bind="confirmation_props"
                @cancel="done_confirming"
                @confirmed="confirmed"
            />
        </div>
    </div>
</template>

<script>
import provinces from "@/custom/provinces.js"
import orderBy from "lodash/orderBy"

import { is_remote_data_table_source, handles_saving, misc_utilities, confirms } from "@/nibnut/mixins"
import { handles_grades } from "@/custom/mixins"

import { ModalDialog, FormInput, FormToggleInput, FormDropdown, BaseLink, DefaultButton, OpenIcon } from "@/nibnut/components"
import CurriculumItem from "./CurriculumItem"

export default {
    name: "Curricula",
    mixins: [is_remote_data_table_source, handles_saving, misc_utilities, confirms, handles_grades],
    components: {
        ModalDialog,
        FormInput,
        FormToggleInput,
        FormDropdown,
        BaseLink,
        DefaultButton,
        OpenIcon,
        CurriculumItem
    },
    methods: {
        confirm_record_delete (row) {
            this.edited_curriculum = row
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: `${this.edited_curriculum.name} (${this.edited_curriculum.province})` }),
                    message: this.$root.translate("Do you really want to delete this curriculum? All related data will also be deleted: links with activities (but activities will be left untouched), analytics data, etc... There is no undo!"),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirm_delete_curriculum_item (curriculum_item) {
            this.edited_curriculum_item = curriculum_item
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: curriculum_item.name }),
                    message: this.$root.translate("Do you really want to delete this curriculum item? All its descendants will also be deleted. There is no undo!"),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-curriculum-item"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.edited_curriculum.id
                    }
                ).then(() => {
                    this.done_confirming()
                }).catch(error => {
                    this.$error(error.message)
                })
            } else if(this.confirming === "delete-curriculum-item") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: "curriculum_item",
                        id: this.edited_curriculum_item.id
                    }
                ).then(() => {
                    this.done_confirming()
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        },
        curriculum_new () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity, reset: true }
            ).then(record => {
                this.edited_curriculum = record
                this.current_subject_tag_id = this.subjects[0].id
                this.current_grade_id = 0
                this.editing_curriculum = true
            }).catch(error => {
                this.$error(error.message)
            })
        },
        curriculum_edit (curriculum) {
            this.$store.dispatch(
                "FETCH_RECORDS",
                {
                    entity: "curriculum_item",
                    query: { curriculum_id: curriculum.id }
                }
            ).then(() => {
                this.edited_curriculum = curriculum
                this.current_grade_id = 0
                this.current_subject_tag_id = this.subjects[0].id
                this.editing_curriculum = true
            }).catch(error => {
                this.$error(error.message)
            })
        },
        save (value, field) {
            if(this.edited_curriculum) {
                if(this.edited_curriculum[field] !== value) this.edited_curriculum[field] = value
                if(this.edited_curriculum.id) return this.save_field(this.edited_curriculum[field], field)
            }
            return Promise.resolve()
        },
        curriculum_create () {
            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: "curriculum",
                    data: this.edited_curriculum
                }
            ).then(record => {
                this.current_records_ids.push(record.id)
                this.edited_curriculum = record
            }).catch(this.receive_error)
        },
        curriculum_item_new (parent_item) {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "curriculum_item", reset: true }
            ).then(record => {
                record.curriculum_id = this.edited_curriculum.id
                record.subject_tag_id = this.current_subject_tag_id
                record.grade = this.current_grade_id
                record.parent_id = parent_item ? parent_item.id : 0
                record.type = parent_item ? parent_item.type : this.constants("curriculum_item_types", "TYPE_OUTCOME").id
                record._parent = parent_item
                this.edited_curriculum_item = record
                this.editing_curriculum_item = true
            }).catch(error => {
                this.$error(error.message)
            })
        },
        save_curriculum_item (value, field) {
            if(this.edited_curriculum_item) {
                if(this.edited_curriculum_item[field] !== value) this.edited_curriculum_item[field] = value
                if(this.edited_curriculum_item.id) return this.save_field(this.edited_curriculum_item[field], field)
            }
            return Promise.resolve()
        },
        toggle_item_type () {
            this.edited_curriculum_item.type = (this.edited_curriculum_item.type === this.constants("curriculum_item_types", "TYPE_ACHIEVEMENT").id) ? this.constants("curriculum_item_types", "TYPE_OUTCOME").id : this.constants("curriculum_item_types", "TYPE_ACHIEVEMENT").id
        },
        curriculum_item_create () {
            const { _parent, ...data } = this.edited_curriculum_item
            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: "curriculum_item",
                    data
                }
            ).then(() => {
                this.editing_curriculum_item = false
            }).catch(this.receive_error)
        }
    },
    computed: {
        state_identifier () {
            return "curricula-list"
        },
        provinces () {
            return provinces.map(province => {
                return { id: province.id, name: province.id }
            })
        },
        subjects () {
            const taxonomy = this.constants("taxonomies", "TAXONOMY_SUBJECT").id
            return orderBy(this.entity_records("tag").filter(tag => tag.taxonomy === taxonomy), "name", "asc")
        }
    },
    data () {
        return {
            entity: "curriculum",
            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                province: { label: this.translate("Prov."), sort: null, type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            editing_curriculum: false,
            edited_curriculum: {},
            current_subject_tag_id: 0,
            current_grade_id: 0,
            editing_curriculum_item: false,
            edited_curriculum_item: {}
        }
    }
}
</script>
