import { is_page } from "@/custom/mixins"
import { is_remote_data_table_source } from "@/nibnut/mixins"

import {
    DefaultButton
} from "@/nibnut/components"
import {
    CustomDateRangeDialog
} from "@/custom/dialogs"

let year_start = null

const is_filterable_page = {
    mixins: [is_page, is_remote_data_table_source],
    components: {
        DefaultButton,
        CustomDateRangeDialog
    },
    methods: {
        this_range (range_id) {
            const to = this.$moment().endOf("day")
            let from = this.year_start
            if(!from) from = this.$moment().startOf("year")
            if(range_id === "week") from = this.$moment.max(from, this.$moment(to).startOf("week"))
            else if(range_id === "month") from = this.$moment.max(from, this.$moment(to).startOf("month"))

            return {
                from,
                to
            }
        },
        range_is (range_dates) {
            return this.$moment(this.state.range_from).isSame(range_dates.from, "day") && this.$moment(this.state.range_to).isSame(range_dates.to, "day")
        },
        set_range (range) {
            const default_range = this.this_range("year")
            if(!range.from) range.from = this.state.range_from || default_range.from
            if(!range.to) range.to = this.state.range_to || default_range.to
            if(range.from.isAfter(range.to)) {
                const date = range.to
                range.to = range.from
                range.from = date
            }
            this.set_state_values({ range_from: range.from.format("YYYY-MM-DD HH:mm:ss"), range_to: range.to.format("YYYY-MM-DD HH:mm:ss") })
            this.refresh()
        },
        start_editing_range () {
            this.edited_range = { from: this.$moment(this.state.range_from), to: this.$moment(this.state.range_to) }
            this.editing_range = true
        },
        stop_editing_range (save = false) {
            if(save) this.set_range(this.edited_range)
            this.edited_range = null
            this.editing_range = false
        }
    },
    computed: {
        year_start () {
            const year_starts_on = this.setting("year_starts_on")
            if(year_starts_on && !year_start) {
                year_start = this.$moment(year_starts_on).startOf("day")
                if(year_start.isAfter()) year_start.subtract(1, "year")
            }
            return year_start
        },
        group_id () {
            return parseInt(this.$route.params.group_id)
        },
        user_id () {
            return parseInt(this.$route.params.user_id) || 0
        },
        is_group () {
            return !!this.group_id && !this.user_id
        },
        is_user () {
            return !this.group_id && !!this.user_id
        },
        is_group_user () {
            return !!this.group_id && !!this.user_id
        },
        owner_entity () {
            const group_id = this.group_id
            const user_id = this.user_id
            if(!!group_id && !!user_id) return "group_user"
            if(group_id) return "group"
            return "user"
        },
        owner () {
            const group_id = this.group_id
            const user_id = this.user_id
            let owner = null
            if(this.is_group_user) owner = this.entity_records("group_user").find(group_user => (group_user.group_id === group_id) && (group_user.user_id === user_id))
            else if(group_id) owner = this.entity_record("group", group_id)
            else if(user_id) owner = this.entity_record("user", user_id)
            return owner
        },
        is_custom_range () {
            return !this.range_is(this.this_range("week")) && !this.range_is(this.this_range("month")) && !this.range_is(this.this_range("year"))
        },
        custom_date_range_label () {
            if(!this.is_custom_range) return this.$root.translate("Custom...")
            return `${this.$moment(this.state.range_from).format("l")} - ${this.$moment(this.state.range_to).format("l")}`
        }
    },
    data () {
        return {
            editing_range: false,
            edited_range: null
        }
    }
}
export default is_filterable_page
