<template>
    <page
        id="tool-page"
        :is-root="true"
        :title="title"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title><span></span></template>

        <div
            v-if="!error"
            id="tool"
        ></div>
        <div
            v-else
            class="empty"
        >
            <div>
                <div class="empty-icon">
                    <open-icon glyph="bomb" size="5x" />
                </div>
                <p class="empty-title h5">{{ $root.translate("Jeepers! That tool can't load!") }}</p>
                <div class="empty-action">
                    <router-link
                        :to="{ name: 'resource.list' }"
                        :title="$root.translate('Pick another tool')"
                        class="btn btn-primary"
                    >
                        {{ $root.translate("Pick another tool") }}
                    </router-link>
                </div>
                <div class="empty-action">
                    <app-logo />
                </div>
            </div>
        </div>
    </page>
</template>

<script>
import { mapGetters } from "vuex"

import { is_page, addl_profile_utilities } from "@/custom/mixins"

import { AppLogo, OpenIcon } from "@/nibnut/components"

export default {
    name: "Tool",
    mixins: [is_page, addl_profile_utilities],
    components: {
        AppLogo,
        OpenIcon
    },
    mounted () {
        this.load()
    },
    watch: {
        $route: "load",
        profile_id: "load"
    },
    methods: {
        load () {
            if(!this.$route.params.id) this.statused(404)
            else if(this.profile_id) {
                this.waiting = true
                this.$store.dispatch(
                    "FETCH_RECORD",
                    {
                        entity: "asset",
                        id: this.$route.params.id,
                        query: {
                            fields: ["fieldset::play"]
                        }
                    }
                ).then(() => {
                    this.load_tool()
                }).catch(error => {
                    console.error(error.message)
                    this.statused(404)
                    this.waiting = false
                })
            }
        },
        load_tool () {
            const tool = this.tool
            const base_url = `/storage/tools/${tool.slug}/`
            window.SAM_AMUSE_CONFIG = {
                base_url,
                call_api: (action, data) => {
                    // console.log("DEBUG call_api", action, data)
                }
            }
            window.SAM_AMUSE_CONFIG.datas = tool.meta ? (tool.meta.data || {}) : {}
            const back_info = this.back_info
            window.SAM_AMUSE_CONFIG.homeurl = (!!back_info && back_info.id) ? back_info.id : "/resources"

            const assets = [...((tool.meta && tool.meta.assets) ? tool.meta.assets : [])]
            const load_next = () => {
                if(assets.length) {
                    const asset_id = assets.shift()
                    const url = asset_id.match(/^http/i) ? asset_id : `${base_url}${asset_id}`

                    let node = document.getElementById(asset_id)
                    if(!node) {
                        let src_attribute = "src"
                        if(asset_id.match(/\.js/i)) {
                            node = document.createElement("script")
                            if(url.match(/tool\.js/)) {
                                window.onerror = error => {
                                    this.error = error
                                    this.waiting = false
                                }
                            }
                        } else {
                            node = document.createElement("link")
                            node.setAttribute("rel", "stylesheet")
                            src_attribute = "href"
                            const matches = url.match(/fonts\.googleapis\.com\/css2\?family=([^:?&]+)/)
                            if(matches) {
                                // force font files to load, so canvas has them available on load
                                // I *think* this is only required for dynamic font URLs like Google's
                                // We may eventually decide this makes more sense either as an extracted list of font families upon game installation OR as font family attribute in the game's meta file
                                const font_loader = document.createElement("div")
                                font_loader.style.position = "absolute"
                                font_loader.style.right = "0px"
                                font_loader.style.bottom = "0px"
                                font_loader.style.background = "transparent"
                                font_loader.style.color = "transparent"
                                font_loader.style.pointerEvents = "none"
                                font_loader.style.fontFamily = matches[1]
                                font_loader.textContent = "."
                                document.body.appendChild(font_loader)
                            }
                        }
                        node.addEventListener("load", () => {
                            if(url.match(/tool\.js/)) {
                                this.waiting = false
                                setTimeout(() => {
                                    window.onerror = null
                                }, 10000)
                            }
                            load_next()
                        })
                        node.setAttribute(src_attribute, url)
                        document.head.appendChild(node)
                    } else load_next()
                }
            }
            load_next()
        }
    },
    computed: {
        ...mapGetters(["entity_record", "history_back_info"]),
        back_info () {
            return this.history_back_info()
        },
        tool () {
            return this.entity_record("tool", parseInt(this.$route.params.id)) || {}
        },
        title () {
            const tool = this.tool
            if(tool) return tool.name
            return this.$root.translate("Another Sam Amuse Tool")
        }
    },
    data () {
        return {
            assignment_id: 0,
            error: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#tool-page {
    #tool {
        & > canvas {
            display: block;
            margin: 0 auto;
        }
    }
    .empty {
        height: 100vh;
        padding: 0;

        & > div {
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
        }
        .las, .lab {
            font-size: 5em;
        }
        .app-logo {
            display: inline-block;
            width: 4rem;
        }
    }
}
</style>
