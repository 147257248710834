<template>
    <div
        :class="{ active: show }"
        class="popover popover-bottom achievement-filter"
    >
        <default-button
            @click.prevent="toggle"
        >
            <span v-if="!!selection">{{ selection.name | nibnut.truncate(32) }}</span>
            <span v-else>({{ $root.translate("All Achievements") }})</span>
        </default-button>
        <div class="popover-container">
            <div class="card">
                <div
                    class="card-body"
                >
                    <table v-if="current_grade.id < 0" class="table table-hover">
                        <thead>
                            <tr>
                                <td class="text-bold">{{ $root.translate("Grades") }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="grade in available_grades"
                                :key="grade.id"
                            >
                                <td>
                                    <default-button
                                        flavor="link"
                                        :block="true"
                                        :disabled="grades.indexOf(grade.id) < 0"
                                        @click.prevent="current_grade = grade"
                                    >
                                        {{ grade.name }}
                                        <open-icon glyph="angle-right" class="float-right" />
                                    </default-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <curriculum-items
                        :curriculum-id="curriculumId"
                        :grade="current_grade.id"
                        :parent-id="0"
                        :class="{ active: (current_grade.id >= 0) }"
                        @loaded="height = $event"
                        @input="$emit('input', $event)"
                        @close="current_grade = { id: -1 }"
                    >
                        {{ current_grade.name }}
                    </curriculum-items>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import { handles_grades } from "@/custom/mixins"

import { DefaultButton, OpenIcon } from "@/nibnut/components"
import CurriculumItems from "./CurriculumItems"

export default {
    name: "AchievementFilter",
    mixins: [handles_grades],
    components: {
        DefaultButton,
        OpenIcon,
        CurriculumItems
    },
    mounted () {
        this.$store.dispatch(
            "FETCH_RECORDS",
            {
                entity: "curriculum_item",
                query: {
                    curriculum_id: this.curriculumId,
                    grade: -1,
                    parent_id: 0
                }
            }
        ).catch(error => {
            this.$error(error.message)
        })
    },
    methods: {
        toggle () {
            this.show = !this.show
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        selection () {
            if(this.value) return this.entity_record("curriculum_item", this.value)
            return null
        }
    },
    props: {
        curriculumId: {
            type: Number,
            required: true
        },
        value: {
            type: Number,
            default: 0
        },
        grades: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            show: false,
            current_grade: { id: -1 }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.achievement-filter {
    .btn {
        white-space: nowrap;
        text-align: left;
    }
    .card-body {
        position: relative;
        height: 75vh;
        overflow: hidden auto;

        .table {
            tbody {
                @include hover-supported {
                    .btn:not(.hover-disabled):hover {
                        border-color: transparent;
                    }
                    & > tr > td {
                        cursor: pointer;
                    }
                }
            }
            & + div {
                padding: $layout-spacing-lg;
                visibility: hidden;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
                transform: translate3d(100%, 0, 0);
                background-color: white;

                &.active {
                    visibility: visible;
                    transform: translate3d(0, 0, 0);
                }
            }
        }
    }
}
</style>
