<template>
    <page
        id="advanced-analytics-troubleshoot"
        :title="title"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <div class="columns mb-8">
            <div class="column">
                <h3 class="text-gray">{{ $root.translate("See where you can help more than {n} students at a time", { n: this.setting("troubleshoot_student_threshold") }) }}</h3>
            </div>
            <div class="column text-right">
                <div class="btn-group">
                    <default-button
                        :active="range_is(this_range('week'))"
                        @click.prevent="set_range(this_range('week'))"
                    >
                        {{ $root.translate('This week') }}
                    </default-button>
                    <default-button
                        :active="range_is(this_range('month'))"
                        @click.prevent="set_range(this_range('month'))"
                    >
                        {{ $root.translate('This month') }}
                    </default-button>
                    <default-button
                        :active="range_is(this_range('year'))"
                        @click.prevent="set_range(this_range('year'))"
                    >
                        {{ $root.translate('This year') }}
                    </default-button>
                    <default-button
                        :active="is_custom_range"
                        @click.prevent="start_editing_range"
                    >
                        {{ custom_date_range_label }}
                    </default-button>
                </div>
            </div>
        </div>

        <div v-if="!achievements.length" class="empty">
            <div class="empty-icon">
                <open-icon glyph="thumbs-up" size="5x" />
            </div>
            <p class="empty-title h5">{{ $root.translate("Beautiful!") }}</p>
            <p class="empty-subtitle">{{ $root.translate("There does not seem to be content that multiple students struggle with.") }}</p>
        </div>
        <troubleshoot-item
            v-for="achievement in achievements"
            :key="achievement.id"
            :achievement="achievement"
            :question-ids="question_ids"
            @replay="replay"
        />

        <custom-date-range-dialog
            :show.sync="editing_range"
            :range="edited_range"
            @close="stop_editing_range(false)"
            @save="stop_editing_range(true)"
        />

        <replay-dialog
            :show.sync="replaying"
            :student="replay_student"
            :assignment-id="replay_assignment_id"
        />
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import is_filterable_page from "./IsFilterablePage"

import { OpenIcon } from "@/nibnut/components"
import { ReplayDialog } from "@/custom/dialogs"
import TroubleshootItem from "./TroubleshootItem"

export default {
    mixins: [is_filterable_page],
    components: {
        OpenIcon,
        ReplayDialog,
        TroubleshootItem
    },
    methods: {
        pre_load () {
            if(this.year_start) {
                if(this.state.filter === null) this.set_state_value("filter", this.user_id ? "activity" : "student")
                if(this.state.range_from === null) {
                    const range = this.this_range("year")
                    this.set_state_values({ range_from: range.from.format("YYYY-MM-DD HH:mm:ss"), range_to: range.to.format("YYYY-MM-DD HH:mm:ss") })
                }
                this.set_state_values({ group_id: this.group_id || 0, user_id: this.user_id || 0 })
            }
        },
        refresh () {
            this.pre_load()

            if(this.can_refresh) {
                const data = {
                    ...this.state,
                    group_id: this.group_id,
                    user_id: this.user_id,
                    fields: this.fields,
                    relation_ids: this.relation_ids
                }

                this.loading = true
                this.$store.dispatch(
                    "RECORDS_ACTION",
                    {
                        entity: this.entity,
                        action: "troubleshoot",
                        data,
                        return_raw: true
                    }
                ).then(response => {
                    const records = (response.data || response).map(record => record.data || record)
                    this.current_records_ids = records.map(record => record.id)

                    let total = 0
                    let found = 0
                    this.question_ids = []
                    if(response.meta) {
                        total = response.meta.total || 0
                        found = response.meta.found || 0

                        if(response.meta.updates && response.meta.updates.question) this.question_ids = response.meta.updates.question.map(question => question.id)
                    }

                    this.set_state_values({
                        total: total,
                        found: found
                    })

                    this.post_load(response)
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading = false
                })
            }
        },
        replay (student, assignment_id) {
            this.replay_assignment_id = assignment_id
            this.replay_student = student
            this.replaying = true
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        fields () {
            return [
                "fieldset::troubleshooting",
                "ns::question;fieldset::troubleshooting",
                "ns::curriculum_item;fieldset::troubleshooting",
                "ns::asset_user;fieldset::troubleshooting",
                "ns::asset;fieldset::troubleshooting",
                "ns::user;fieldset::troubleshooting",
                "ns::group_user;fieldset::troubleshooting",
                "ns::group;fieldset::troubleshooting"
            ]
        },
        title () {
            let title = this.$root.translate("Troubleshoot")
            const owner = this.owner
            if(owner) {
                if(this.is_group) title = this.$root.translate("Troubleshoot {name}", { name: owner.name })
                else title = this.$root.translate("Troubleshoot {name}", { name: owner.username })
            }
            if(!this.state.range_from || !this.state.range_to) return title
            return `${title} (${this.$moment(this.state.range_from).format("l")} - ${this.$moment(this.state.range_to).format("l")})`
        },
        rows () {
            const range = [this.$moment(this.state.range_from), this.$moment(this.state.range_to)]
            return this.entity_records(this.entity, this.current_records_ids).filter(row => {
                const date = this.$moment(row.ended_at)
                return date.isSameOrAfter(range[0]) && date.isSameOrBefore(range[1])
            })
        },
        achievements () {
            const curriculum_item_ids = [...new Set(this.rows.map(record => record.computed_curriculum_item_id))]
            return this.entity_records("curriculum_item", curriculum_item_ids)
        }
    },
    data () {
        return {
            entity: "answer",
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "date",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                range_from: null,
                range_to: null,
                achievement_id: 0
            },
            question_ids: [],
            replay_assignment_id: 0,
            replay_student: {},
            replaying: false
        }
    }
}
</script>

<style lang="scss">
#advanced-analytics-troubleshoot {
}
</style>
