<template>
    <div>
        <table class="table table-hover">
            <thead>
                <tr>
                    <td class="text-bold">
                        <default-button
                            flavor="link"
                            size="sm"
                            class="mr-2"
                            @click.prevent="$emit('close')"
                        >
                            <open-icon glyph="angle-left" />
                        </default-button>
                        <slot></slot>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-if="loading">
                    <td><loader size="sm" /></td>
                </tr>
                <tr
                    v-for="item in rows"
                    :key="item.id"
                >
                    <td>
                        <default-button
                            v-if="item.type !== 'achievement'"
                            flavor="link"
                            :block="true"
                            :disabled="!item_has_children(item)"
                            @click.prevent="selection = item"
                        >
                            {{ item.name }}
                            <open-icon glyph="angle-right" class="float-right" />
                        </default-button>
                        <default-button
                            v-else
                            flavor="link"
                            :block="true"
                            @click.prevent="$emit('input', item.id)"
                        >
                            {{ item.name }}
                        </default-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <curriculum-items
            v-if="has_children"
            :curriculum-id="curriculumId"
            :grade="grade"
            :parent-id="selection.id"
            :class="{ active: (selection.id >= 0) }"
            @input="$emit('input', $event)"
            @close="selection = { id: -1 }"
        >
            {{ selection.name }}
        </curriculum-items>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
// import orderBy from "lodash/orderBy"

import { DefaultButton, OpenIcon } from "@/nibnut/components"
import { Loader } from "@/custom/components"

export default {
    name: "CurriculumItems",
    components: {
        CurriculumItems: () => import("./CurriculumItems"),
        DefaultButton,
        OpenIcon,
        Loader
    },
    mounted () {
        this.reload()
    },
    watch: {
        grade: "reload",
        parentId: "reload"
    },
    methods: {
        reload () {
            if((this.grade >= 0) && (this.parentId >= 0)) {
                this.loading = true
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "curriculum_item",
                        query: {
                            curriculum_id: this.curriculumId,
                            grade: this.grade,
                            parent_id: this.parentId,
                            relation_ids: ["curriculum_item"]
                        }
                    }
                ).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading = false
                })
            }
        },
        item_has_children (item) {
            if(!item.id || (item.type === "achievement")) return false
            return !!this.entity_records("curriculum_item").filter(curriculum_item => curriculum_item.parent_id === item.id).length
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        rows () {
            if(this.loading) return []
            return this.entity_records("curriculum_item").filter(curriculum_item => (curriculum_item.grade === this.grade) && (curriculum_item.parent_id === this.parentId))
        },
        selection_has_children () {
            return this.item_has_children(this.selection)
        },
        has_children () {
            return !!this.rows.find(item => !!this.item_has_children(item))
        }
    },
    props: {
        curriculumId: {
            type: Number,
            required: true
        },
        grade: {
            type: Number,
            required: true
        },
        parentId: {
            type: Number,
            required: true,
            default: -1
        }
    },
    data () {
        return {
            loading: false,
            selection: { id: -1 }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";
</style>
