<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("Student Management") }}</div>
        </div>
        <div class="card-body">
            <div class="columns mb-8">
                <div class="column col-6 col-sm-12">
                    <form-input
                        id="session_timeout"
                        name="session_timeout"
                        v-model="record.session_timeout"
                        type="number"
                        :min="1"
                        :required="false"
                        :saving="saving('session_timeout')"
                        :error="has_error('session_timeout')"
                        @input="save"
                    >
                        <template v-slot:label>
                            {{ $root.translate("Session Timeout") }}
                        </template>
                        <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("minutes") }}</span></template>
                    </form-input>
                </div>
                <div class="column col-6 col-sm-12">
                    <form-input
                        id="question_timeout"
                        name="question_timeout"
                        v-model="record.question_timeout"
                        type="number"
                        :min="1"
                        :required="false"
                        :saving="saving('question_timeout')"
                        :error="has_error('question_timeout')"
                        @input="save"
                    >
                        <template v-slot:label>
                            {{ $root.translate("Question Timeout") }}
                            <help-button
                                size="sm"
                                class="popover-right ml-2"
                            >
                                <p>{{ $root.translate("This value is used to determine if a student is stuck on a question during a game.") }}</p>
                                <p>{{ $root.translate("When a student is stuck, a warning is sent to his/her teacher.") }}</p>
                            </help-button>
                        </template>
                        <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("seconds") }}</span></template>
                    </form-input>
                </div>
            </div>

            <h6>{{ $root.translate("Password Modes") }}</h6>
            <div class="columns">
                <div class="column col-6 col-sm-12">
                    <form-dropdown
                        id="min_grade_for_image_password"
                        name="min_grade_for_image_password"
                        :value="record.min_grade_for_image_password"
                        :options="raw_grades"
                        :empty-value="-1"
                        :empty-label="$root.translate('Select a grade')"
                        :required="true"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Visual") }}</template>
                        <template v-slot:hint>{{ $root.translate("This grade or more will use visual passwords") }}</template>
                    </form-dropdown>
                </div>
                <div class="column col-6 col-sm-12">
                    <form-dropdown
                        id="min_grade_for_text_password"
                        name="min_grade_for_text_password"
                        :value="record.min_grade_for_text_password"
                        :options="raw_grades"
                        :empty-value="-1"
                        :empty-label="$root.translate('Select a grade')"
                        :required="true"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Text") }}</template>
                        <template v-slot:hint>{{ $root.translate("This grade or more will use textual passwords") }}</template>
                    </form-dropdown>
                </div>
            </div>

            <avatars
                class="mt-8"
            />

            <password-tokens
                type="password"
                class="mt-8"
            />
        </div>
    </div>
</template>

<script>
import { handles_grades } from "@/custom/mixins"
import { handles_saving } from "@/nibnut/mixins"

import { FormInput, FormDropdown, HelpButton } from "@/nibnut/components"
import Avatars from "./Avatars"
import PasswordTokens from "./PasswordTokens"

export default {
    name: "StudentManagement",
    mixins: [handles_saving, handles_grades],
    components: {
        FormInput,
        FormDropdown,
        HelpButton,
        Avatars,
        PasswordTokens
    },
    methods: {
        save (value, field) {
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.record.id) {
                    this.record_id = this.record.id
                    return this.save_field(this.record[field], field)
                }
            }
            return Promise.resolve()
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "setting",
            entityName: "Setting:::Settings"
        }
    }
}
</script>

<style lang="scss">
#settings {
    td {
        img {
            max-height: 1rem;
            vertical-align: middle;
        }
    }
    .image-holder {
        position: relative;

        & > div {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
