<template>
    <div style="height: 100%;">
        <image-upload-input
            :id="`url-${this.attachment.id}`"
            name="url"
            :url="attachment.url"
            :alt="attachment.name"
            :accepts="upload_accepts('url')"
            :uploading="!!uploading['url']"
            :uploaded="uploaded['url'] || 0"
            :error="has_error('url')"
            :replaceable="true"
            :deletable="true"
            :glyph="glyph"
            :delete-button-title="deleteButtonTitle"
            :replace-title="replaceTitle"
            :disabled="disabled"
            @upload="upload_file_list('url', $event)"
            @clear="confirm_clear_file('url')"
        />
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { handles_uploads, handles_errors } from "@/nibnut/mixins"

import { ImageUploadInput } from "@/nibnut/components"

export default {
    name: "Uploader",
    mixins: [handles_uploads, handles_errors],
    components: {
        ImageUploadInput
    },
    methods: {
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(png|jpe?g)$/i)
            return "image/jpeg,image/png,.jpg,.jpeg,.png"
        },
        upload_data (mode) {
            return {
                entity: this.entity,
                id: this.attachment.id
            }
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detached (name, response) {
            this.done_confirming()
            this.$store.dispatch("SWEEP", { entity: "attachment", id: this.attachment.id })
        },
        confirm_clear_file (name) {
            this.confirming_clear_file = name
            this.confirm(
                {
                    title: this.$root.translate("Remove uploaded file"),
                    message: this.$root.translate("Do you really want to delete the uploaded file? Make sure you have a copy if you think you'll need it again some day."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Remove")
                },
                "clear-file"
            )
        },
        confirmed () {
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: this.entity,
                    id: this.attachment.id
                }
            ).then(() => {
                this.done_confirming()
            }).catch(error => {
                this.$error(error.message)
            })
        }
    },
    computed: {
        upload_input_props () {
            const { entity, owner, attachment, ...upload_input_props } = this.$props
            return upload_input_props
        },
        holding_mode () {
            return false
        }
    },
    props: {
        attachment: {
            type: Object,
            validator: prop => !!prop,
            required: true
        },
        replaceable: {
            type: Boolean,
            default: true
        },
        deletable: {
            type: Boolean,
            default: true
        },
        glyph: {
            type: String,
            default: "cloud-upload-alt"
        },
        deleteButtonTitle: {
            type: String,
            default: ""
        },
        replaceTitle: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "attachment"
        }
    }
}
</script>
