<template>
    <div v-if="!!record.id">
        <div class="card mb-8">
            <div class="card-header">
                <div class="card-title h5">{{ $root.translate("Insitutions Settings") }}</div>
            </div>
            <div class="card-body">
                <div class="columns">
                    <div class="column col-4 col-md-12 col-sm-12">
                        <form-date-input
                            id="year_starts_on"
                            name="year_starts_on"
                            v-model="record.year_starts_on"
                            :min="$moment().startOf('day').add(1, 'day')"
                            :required="false"
                            :saving="saving('year_starts_on')"
                            :error="has_error('year_starts_on')"
                            @input="save"
                        >
                            <template v-slot:label>
                                {{ $root.translate("School Year Starts On") }}
                                <help-button
                                    size="sm"
                                    class="popover-right ml-2"
                                >
                                    <p>{{ $root.translate("Enter the start date of the *next* school year.") }}</p>
                                    <p>{{ $root.translate("This date determines when the application encourages teachers to archive students from the past year, and create the upcoming year's group(s).") }}</p>
                                </help-button>
                            </template>
                        </form-date-input>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column col-6 col-sm-12 mb-2">
                <institutions />
            </div>
            <div class="column col-6 col-sm-12 mb-2">
                <curricula />
            </div>
        </div>
    </div>
</template>

<script>
import is_settings_panel from "./IsSettingsPanel"

import { FormDateInput, HelpButton } from "@/nibnut/components"
import Institutions from "./Institutions"
import Curricula from "./Curricula"

export default {
    name: "InstitutionsPanel",
    mixins: [is_settings_panel],
    components: {
        FormDateInput,
        HelpButton,
        Curricula,
        Institutions
    }
}
</script>
