<template>
    <page
        id="advanced-analytics"
        :title="title"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <div
            v-if="stats"
            class="columns mb-8"
        >
            <div class="column col-sm-12 mb-4">
                <count-up-card
                    :to="stats.questions_answered"
                    glyph="question-circle"
                >
                    {{ $root.translate("questions answered") }}
                </count-up-card>
            </div>
            <div class="column col-sm-12 mb-4">
                <count-up-card
                    :to="stats.total_play_time * 1000"
                    :as-time="true"
                    glyph="stopwatch"
                >
                    {{ $root.translate("played") }}
                </count-up-card>
            </div>
            <div class="column col-sm-12 mb-4">
                <count-up-card
                    :to="stats.skills"
                    glyph="lightbulb"
                >
                    {{ $root.translate("skills progressed") }}
                </count-up-card>
            </div>
        </div>
        <div
            v-if="stats"
            class="columns mb-8"
        >
            <div class="column col-sm-12 mb-4">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">
                            <ul class="tab tab-block">
                                <li
                                    :class="{ active: activities_tab === 'preferences' }"
                                    class="tab-item"
                                >
                                    <base-link href="#" @click.prevent.stop="activities_tab = 'preferences'">
                                        {{ $root.translate("Top Activities") }}
                                    </base-link>
                                </li>
                                <li
                                    :class="{ active: activities_tab === 'recent' }"
                                    class="tab-item"
                                >
                                    <base-link href="#" @click.prevent.stop="activities_tab = 'recent'">
                                        {{ $root.translate("Recent Activities") }}
                                    </base-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body full-width">
                        <table class="table table-striped">
                            <tbody>
                                <tr
                                    v-for="row in stats[activities_tab]"
                                    :key="row.id"
                                >
                                    <td>{{ row.name }}</td>
                                    <td v-if="!!row.duration">
                                        <span v-if="row.duration > 3600">{{ (row.duration / 3600) | nibnut.number("0,0.0") }} h</span>
                                        <span v-else-if="!!row.duration">{{ (row.duration / 60) | nibnut.number("0,0.0") }} min</span>
                                        <span v-else class="text-gray">0 min</span>
                                    </td>
                                    <td v-if="!!row.last_seen_at">{{ $moment(row.last_seen_at).fromNow() }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div
                v-if="stats.locations.home || stats.locations.school"
                class="column col-sm-12 mb-4"
            >
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">
                            {{ $root.translate("Locations") }}
                        </div>
                    </div>
                    <div class="card-body">
                        <apexchart
                            :height="240"
                            type="pie"
                            :options="chart_options('locations')"
                            :series="chart_data('locations')"
                        />
                    </div>
                </div>
            </div>
            <div class="column col-sm-12 mb-4">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">
                            {{ $root.translate("Achievements") }}
                        </div>
                    </div>
                    <div class="card-body">
                        <apexchart
                            :height="240"
                            type="pie"
                            :options="chart_options('achievements')"
                            :series="chart_data('achievements')"
                        />
                    </div>
                </div>
            </div>
        </div>
        <data-table
            v-if="setting('year_starts_on')"
            id="analytics"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @click="maybe_edit_record"
        >
            <template
                v-slot:controls
            >
                <div>
                    <form-dropdown
                        id="filter"
                        name="filter"
                        v-model="state.filter"
                        :options="filters"
                        :required="true"
                        @input="filter('type', $event)"
                    />
                </div>
                <div v-if="state.filter === 'achievement'">
                    <achievement-filter
                        :curriculum-id="owner ? (owner.curriculum_id || 0) : 0"
                        :value="state.achievement_id"
                        :grades="grades"
                        @input="filter_by('achievement_id', $event)"
                    />
                </div>
                <div>
                    <div class="btn-group">
                        <default-button
                            :active="range_is(this_range('week'))"
                            @click.prevent="set_range(this_range('week'))"
                        >
                            {{ $root.translate('This week') }}
                        </default-button>
                        <default-button
                            :active="range_is(this_range('month'))"
                            @click.prevent="set_range(this_range('month'))"
                        >
                            {{ $root.translate('This month') }}
                        </default-button>
                        <default-button
                            :active="range_is(this_range('year'))"
                            @click.prevent="set_range(this_range('year'))"
                        >
                            {{ $root.translate('This year') }}
                        </default-button>
                        <default-button
                            :active="is_custom_range"
                            @click.prevent="start_editing_range"
                        >
                            {{ custom_date_range_label }}
                        </default-button>
                    </div>
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    {{ row.date | nibnut.date("YYYY-MM-DD") }} - <base-link v-if="state.filter === 'student'" :href="{ name: 'student.edit', params: { id: row.id } }">{{ row.name }}</base-link><span v-else>{{ row.name }}</span>
                </h6>
                <div>
                    {{ (row.duration / 60) | nibnut.number() }} {{ $root.translate("min") }}
                </div>
                <div v-if="!!row.idle_time" class="text-small text-gray">{{ $root.translate("Idle") }}: {{ (row.idle_time / 60) | nibnut.number() }} {{ $root.translate("min") }}</div>
                <div class="text-small">
                    {{ $root.translate("{n} Question:::{n} Questions", { n: row.question_count }, row.question_count) }}
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <base-link
                    v-if="field === 'name' && state.filter === 'student'"
                    :href="{ name: 'student.edit', params: { id: row.id } }"
                    :title="$root.translate('Student Profile')"
                >
                    {{ row[field] }}
                </base-link>
                <span v-else-if="field === 'date'">
                    {{ row.date | nibnut.date("YYYY-MM-DD") }}
                </span>
                <span v-else-if="field.match(/^(duration|idle_time)$/i)">
                    {{ (row[field] / 60) | nibnut.number() }} {{ $root.translate("min") }}
                </span>
                <span v-else-if="field === 'question_count'">
                    {{ row.question_count | nibnut.number("0,0") }}
                </span>
                <span v-else>
                    {{ row[field] }}
                </span>
            </template>
        </data-table>

        <custom-date-range-dialog
            :show.sync="editing_range"
            :range="edited_range"
            @close="stop_editing_range(false)"
            @save="stop_editing_range(true)"
        />
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import { crud_utilities } from "@/nibnut/mixins"
import is_filterable_page from "./IsFilterablePage"

import {
    FormDropdown,
    BaseLink
} from "@/nibnut/components"
import CountUpCard from "./CountUpCard"
import VueApexCharts from "vue-apexcharts"
import AchievementFilter from "./AchievementFilter"

export default {
    mixins: [is_filterable_page, crud_utilities],
    components: {
        FormDropdown,
        BaseLink,
        CountUpCard,
        Apexchart: VueApexCharts,
        AchievementFilter
    },
    methods: {
        pre_load () {
            if(this.year_start) {
                if(this.state.filter === null) this.set_state_value("filter", this.user_id ? "activity" : "student")
                if(this.state.range_from === null) {
                    const range = this.this_range("year")
                    this.set_state_values({ range_from: range.from.format("YYYY-MM-DD HH:mm:ss"), range_to: range.to.format("YYYY-MM-DD HH:mm:ss") })
                }
                this.set_state_values({ group_id: this.group_id || 0, user_id: this.user_id || 0 })
            }
        },
        reset () {
            if(this.profile_id) {
                this.$store.dispatch(
                    "RECORDS_ACTION",
                    {
                        entity: this.entity,
                        action: "analytics",
                        data: {
                            group_id: this.group_id,
                            user_id: this.user_id,
                            fields: ["ns::user;fieldset::analytics", "ns::group_user;fieldset::analytics", "ns::group;fieldset::analytics"]
                        },
                        passthru: true
                    }
                ).then(data => {
                    this.stats = data
                })
            }
            this.reset_state()
            this.refresh()
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            return rows
        },
        filter_by (filter_by, filter) {
            this.filtered_by_selection = false
            this.set_state_values({
                page: 1,
                [filter_by]: filter
            })
            this.refresh()
        },
        chart_options (graph_id) {
            if(graph_id === "achievements") {
                return {
                    chart: {
                        id: "analytics-achievements",
                        height: 240,
                        fontFamily: "QuickSand",
                        type: "pie"
                    },
                    dataLabels: {
                        enabled: true
                    },
                    legend: { show: false },
                    labels: this.stats.achievements.map(achievement => achievement.name),
                    colors: ["#B6007F", "#285A74", "#D06200", "#3B8C13", "#800363", "#285a74"],
                    tooltip: {
                        y: {
                            formatter: (value) => {
                                return `${this.$numeral(value).format("0,0.0")}%`
                            }
                        }
                    }
                }
            }
            return {
                chart: {
                    id: "analytics-locations",
                    height: 240,
                    fontFamily: "QuickSand",
                    type: "pie"
                },
                dataLabels: {
                    enabled: true
                },
                legend: { show: false },
                labels: [this.$root.translate("Home"), this.$root.translate("School")],
                colors: ["#B6007F", "#285A74"],
                tooltip: {
                    y: {
                        formatter: (value) => {
                            return `${this.$numeral(value).format("0,0.0")}%`
                        }
                    }
                }
            }
        },
        chart_data (graph_id) {
            if(graph_id === "achievements") {
                const total = this.stats.achievements.reduce((accumulator, current_value) => {
                    return accumulator + (current_value.duration || 0)
                }, 0)
                return this.stats.achievements.map(achievement => (achievement.duration / total) * 100)
            }
            const total = this.stats.locations.home + this.stats.locations.school
            return [(this.stats.locations.home / total) * 100, (this.stats.locations.school / total) * 100]
        },
        maybe_edit_record (row) {
            if(this.state.filter === "student") this.$router.push({ name: "student.edit", params: { id: row.route_id } })
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        fields () {
            return ["fieldset::analytics-list", "ns::curriculum;fieldset::analytics-list"]
        },
        title () {
            let title = this.$root.translate("Advanced Statistics")
            const owner = this.owner
            if(owner) {
                if(this.is_group) title = this.$root.translate("Statistics for {name}", { name: owner.name })
                else title = this.$root.translate("Statistics for {name}", { name: owner.username })
            }
            if(!this.state.range_from || !this.state.range_to) return title
            return `${title} (${this.$moment(this.state.range_from).format("l")} - ${this.$moment(this.state.range_to).format("l")})`
        },
        filters () {
            const filters = [
                { id: "activity", name: this.$root.translate("Activities") },
                { id: "achievement", name: this.$root.translate("Achievements") }
            ]
            if(!this.user_id) filters.push({ id: "student", name: this.$root.translate("Students") })
            return filters
        },
        grades () {
            const owner = this.owner
            if(!owner) return []
            const curriculum = this.entity_record("curriculum", owner.curriculum_id)
            return curriculum ? curriculum.grades : []
        }
    },
    data () {
        return {
            entity: "usage_statistic",
            relation_ids: ["curriculum"],
            columns: {
                date: { label: this.translate("Last Used"), sort: "desc", type: "numeric" },
                name: { label: this.translate("Name"), sort: null, type: "alpha" },
                // location: { label: this.translate("Location"), sort: false, head_class: "text-center", cell_class: "text-center" },
                duration: { label: this.translate("Duration"), sort: null, type: "numeric" },
                idle_time: { label: this.translate("Idle"), sort: null, type: "numeric" },
                question_count: { label: this.translate("Questions"), sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "date",
                sort_dir: "desc",
                filter_on: "type",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                range_from: null,
                range_to: null,
                achievement_id: 0
            },
            stats: null,
            activities_tab: "preferences"
        }
    }
}
</script>

<style lang="scss">
#advanced-analytics {
    .card {
        height: 100%;
        & > .card-body {
            &.full-width {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0;

                .table td {
                    border: 0;
                }
            }
        }
    }

    .btn-group {
        flex-wrap: nowrap;
    }
}
</style>
