<template>
    <div v-if="!!record.id">
        <div class="columns">
            <div class="column col-6 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ $root.translate("Subscription Settings") }}</div>
                    </div>
                    <div class="card-body">
                        <div class="columns">
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="min_subscription_seats"
                                    name="min_subscription_seats"
                                    v-model="record.min_subscription_seats"
                                    type="number"
                                    :min="1"
                                    :required="false"
                                    :saving="saving('min_subscription_seats')"
                                    :error="has_error('min_subscription_seats')"
                                    @input="save"
                                >
                                    <template v-slot:label>
                                        {{ $root.translate("Minimum Seats Per Subscription") }}
                                        <help-button
                                            size="sm"
                                            class="popover-right ml-2"
                                        >
                                            <p>{{ $root.translate("You can override this for specific users if you like.") }}</p>
                                        </help-button>
                                    </template>
                                    <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("seats") }}</span></template>
                                </form-input>
                            </div>
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="subscription_cancellation_warning_delay"
                                    name="subscription_cancellation_warning_delay"
                                    v-model="record.subscription_cancellation_warning_delay"
                                    type="number"
                                    :min="1"
                                    :required="false"
                                    :saving="saving('subscription_cancellation_warning_delay')"
                                    :error="has_error('subscription_cancellation_warning_delay')"
                                    @input="save"
                                >
                                    <template v-slot:label>
                                        {{ $root.translate("Cancellation Reminder") }}
                                        <help-button
                                            size="sm"
                                            class="popover-right ml-2"
                                        >
                                            <p>{{ $root.translate("When a user cancels their subscription, they still have access until the end of the period they paid for.") }}</p>
                                            <p>{{ $root.translate("The system sends a reminder shortly before the subscription actually ends, to give subscribers a chance to 'uncancel' their subscription.") }}</p>
                                        </help-button>
                                    </template>
                                    <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("day(s) before") }}</span></template>
                                </form-input>
                            </div>
                            <div class="column col-12">
                                <form-dropdown
                                    id="auto_apply_coupon_code"
                                    name="auto_apply_coupon_code"
                                    v-model="record.auto_apply_coupon_code"
                                    :options="coupons"
                                    id-field="stripe_id"
                                    empty-value=""
                                    :empty-label="translate('No Coupon')"
                                    :required="false"
                                    :error="has_error('auto_apply_coupon_code')"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ $root.translate("Auto-Apply Coupon") }}</template>
                                    <template v-slot:hint>{{ $root.translate("If you select a coupon here, ALL new subscribers will get that coupon applied until you turn this off.") }}</template>
                                </form-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-6 col-sm-12">
                <tax-rates />
            </div>
        </div>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"
import { mapGetters } from "vuex"

import is_settings_panel from "./IsSettingsPanel"

import { FormInput, FormDropdown, HelpButton } from "@/nibnut/components"
import TaxRates from "./TaxRates"

export default {
    name: "FinancesPanel",
    mixins: [is_settings_panel],
    components: {
        FormInput,
        FormDropdown,
        HelpButton,
        TaxRates
    },
    mounted () {
        this.$store.dispatch(
            "FETCH_RECORDS",
            {
                entity: "coupon",
                query: {
                    filter_on: "status",
                    filter: "valid",
                    fields: ["fieldset::picker"]
                }
            }
        ).catch(error => {
            console.error(error.message)
        })
    },
    computed: {
        ...mapGetters(["entity_records"]),
        coupons () {
            return orderBy(this.entity_records("coupon"), "name", "asc")
        }
    }
}
</script>
