<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("Terms") }}</div>
        </div>
        <div class="card-body">
            <data-table
                id="tags-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="true"
                :export-url="export_url"
                @sort="sort_by"
                @search="search"
                @page="goto_page"
                @add="tag_new"
                @click="tag_edit($event)"
            >
                <template
                    v-slot:controls
                >
                    <div>
                        <form-dropdown
                            id="filter"
                            name="filter"
                            :value="state.filter"
                            :options="taxonomies"
                            :required="true"
                            @input="filter('taxonomy', $event)"
                        />
                    </div>
                </template>
                <template
                    v-slot:summary="{ row }"
                >
                    <h6>
                        <span
                            v-if="colorized"
                            class="dot"
                            :style="{ 'background-color': row.color }"
                        ></span>
                        {{ row.name }}
                    </h6>
                    <default-button
                        color="error"
                        :block="true"
                        class="mt-4"
                        @click.prevent="confirm_record_delete(row)"
                    >
                        <open-icon glyph="trash" class="mr-2" />
                        {{ $root.translate("Delete") }}
                    </default-button>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <span v-if="field === 'name'">
                        <span
                            v-if="colorized"
                            class="dot"
                            :style="{ 'background-color': row.color }"
                        ></span>
                        {{ translate_field(row, "name") }}
                    </span>
                    <div
                        v-else-if="field === '_buttons'"
                        class="text-right"
                    >
                        <default-button
                            flavor="link"
                            color="error"
                            size="sm"
                            :title="$root.translate('Delete')"
                            @click.prevent.stop="confirm_record_delete(row)"
                        >
                            <open-icon glyph="trash" />
                        </default-button>
                    </div>
                    <span v-else>{{ row[field] }}</span>
                </template>
            </data-table>

            <modal-dialog
                id="tag-editor"
                :show.sync="editing_tag"
            >
                <template v-slot:title>
                    <span v-if="!edited_tag.id" class="h5">
                        <span
                            v-if="colorized"
                            class="dot"
                            :style="{ 'background-color': edited_tag.color }"
                        ></span>
                        {{ $root.translate("New Term") }}
                    </span>
                    <span v-else class="h5">
                        <span
                            v-if="colorized"
                            class="dot"
                            :style="{ 'background-color': edited_tag.color }"
                        ></span>
                        {{ edited_tag.name }}
                    </span>
                </template>

                <form-input
                    id="name"
                    name="name"
                    v-model="edited_tag.name"
                    :required="true"
                    :saving="saving('name')"
                    :error="has_error('name')"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("Name") }}</template>
                </form-input>
                <form-input
                    id="name_fr"
                    name="name_fr"
                    v-model="edited_tag.name_fr"
                    :required="true"
                    :saving="saving('name_fr')"
                    :error="has_error('name_fr')"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("Name") }} (FR)</template>
                </form-input>

                <div
                    v-if="colorized"
                    class="form-group"
                >
                    <label
                        class="form-label mb-2"
                    >
                        {{ $root.translate("Color") }}
                    </label>
                    <v-swatches
                        :value="edited_tag.color"
                        :swatches="['#F59A06', '#85C641', '#800363', '#3B9BD1', '#B6007F', '#D06200', '#3B8C13', '#285A74', '#CE74BB']"
                        inline
                        @input="save($event, 'color')"
                    />
                </div>

                <template v-slot:footer>
                    <div class="text-center">
                        <default-button
                            v-if="!edited_tag.id"
                            class="mr-2"
                            @click.prevent="editing_tag=false"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                        <default-button
                            v-if="!edited_tag.id"
                            class="ml-2"
                            @click.prevent="tag_create"
                        >
                            {{ $root.translate("Create") }}
                        </default-button>
                        <default-button
                            v-if="!!edited_tag.id"
                            @click.prevent="save_and_close"
                        >
                            {{ $root.translate("Done") }}
                        </default-button>
                    </div>
                </template>
            </modal-dialog>

            <confirmation
                v-if="!!confirming"
                v-bind="confirmation_props"
                @cancel="done_confirming"
                @confirmed="confirmed"
            />
        </div>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import { is_remote_data_table_source, handles_saving, misc_utilities, confirms } from "@/nibnut/mixins"

import { ModalDialog, FormDropdown, FormInput, DefaultButton, OpenIcon } from "@/nibnut/components"
import VSwatches from "vue-swatches"

import "vue-swatches/dist/vue-swatches.css"

export default {
    name: "Institutions",
    mixins: [is_remote_data_table_source, handles_saving, misc_utilities, confirms],
    components: {
        ModalDialog,
        FormDropdown,
        FormInput,
        DefaultButton,
        OpenIcon,
        VSwatches
    },
    methods: {
        pre_load () {
            if(this.profile_id && !this.state.filter) this.set_state_value("filter", this.taxonomies[0].id)
        },
        confirm_record_delete (row) {
            this.edited_tag = row
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: `${this.edited_tag.name} (${this.edited_tag.state})` }),
                    message: this.$root.translate("Do you really want to delete this tag? There is no undo!"),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.edited_tag.id
                    }
                ).then(() => {
                    this.done_confirming()
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        },
        tag_new () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity, reset: true }
            ).then(record => {
                this.edited_tag = record
                this.edited_tag.taxonomy = this.state.filter
                this.editing_tag = true
            }).catch(error => {
                this.$error(error.message)
            })
        },
        tag_edit (tag) {
            this.edited_tag = tag
            this.editing_tag = true
        },
        save (value, field) {
            if(this.edited_tag) {
                if(this.edited_tag[field] !== value) this.edited_tag[field] = value
                if(this.edited_tag.id) return this.save_field_for_record_id(this.entity, this.edited_tag.id, this.edited_tag[field], field)
            }
            return Promise.resolve()
        },
        save_and_close () {
            this.editing_tag = false
        },
        tag_create () {
            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: this.entity,
                    data: this.edited_tag
                }
            ).then(record => {
                this.current_records_ids.push(record.id)
                this.editing_tag = false
            }).catch(this.receive_error)
        }
    },
    computed: {
        state_identifier () {
            return "tags-list"
        },
        fields () {
            return ["fieldset::default"]
        },
        taxonomies () {
            return orderBy(Object.values(this.constants("taxonomies")), "name", "asc")
        },
        colorized () {
            return (this.state.filter === this.constants("taxonomies", "TAXONOMY_SUBJECT").id)
        }
    },
    data () {
        return {
            entity: "tag",
            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: "taxonomy",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            editing_tag: false,
            edited_tag: {}
        }
    }
}
</script>
