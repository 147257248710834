<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("Account Lockdowns") }}</div>
            <div class="card-subtitle text-gray">{{ $root.translate("When a group password appears to be abused, (too many wrong guesses) the IP address of the user will be locked down. Use this list to unlock a particular IP address.") }}</div>
            <div class="card-subtitle text-gray">{{ $root.translate("To find out a user's IP address, ask him/her to type 'What's my IP?' in Google.") }}</div>
        </div>
        <div class="card-body">
            <data-table
                id="lockdowns-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="false"
                :empty-title="$root.translate('No accounts on lockdown')"
                @sort="sort_by"
                @search="search"
                @page="goto_page"
            >
                <template
                    v-slot:summary="{ row }"
                >
                    <h6>{{ row.ip }}</h6>
                    <div class="test-gray">
                        {{ $root.translate("Unlocks on") }}: {{ row.computed_expires_at | nibnut.date("YYYY-MM-DD HH:mm") }}
                    </div>
                    <default-button
                        color="primary"
                        :block="true"
                        class="mt-4"
                        @click.prevent="delete_record(row)"
                    >
                        {{ $root.translate("Unlock IP Address") }}
                    </default-button>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div v-if="field === 'computed_expires_at'">
                        {{ row.computed_expires_at | nibnut.date("YYYY-MM-DD HH:mm") }}
                    </div>
                    <div v-else-if="field === '_buttons'" class="text-right">
                        <default-button
                            flavor="link"
                            size="sm"
                            :title="$root.translate('Unlock IP Address')"
                            @click.prevent="delete_record(row)"
                        >
                            <open-icon glyph="unlock-alt" />
                        </default-button>
                    </div>
                    <span v-else>{{ row[field] }}</span>
                </template>
            </data-table>
        </div>
    </div>
</template>

<script>
import { is_remote_data_table_source } from "@/nibnut/mixins"

import { DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "Lockdowns",
    mixins: [is_remote_data_table_source],
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        delete_record (lockdown) {
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: this.entity,
                    id: lockdown.id
                }
            ).then(() => {
                this.refresh()
            }).catch(error => {
                this.$error(error.message)
            })
        }
    },
    data () {
        return {
            entity: "lockdown",
            columns: {
                ip: { label: this.translate("IP Address"), sort: "asc", type: "alpha" },
                computed_expires_at: { label: this.translate("Unlocks on"), sort: null, type: "amount" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "ip",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
